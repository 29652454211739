import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import CleanNavbar from '../components/CleanNavbar';
import AboutImage from '../img/about_image_square.jpg';
import Signature from '../img/signature.png';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`

const BigContainer = styled.div`
    margin-top: 2.5rem;
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    @media (max-width: 600px) {
        width: 85%;
    }
    margin-bottom: 10rem;
`

const TitleText = styled.h1`
    font-family: 'Righteous';
    font-size: 50px;
    margin-bottom: 20px;
    box-sizing: border-box;
    text-align: center;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

const Message = styled.div`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`

const MailLink = styled.a`
    font-family: 'Lora', serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    text-decoration: none;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`

const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    width: 50%;
    max-width: 800px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem;
    color: #ffffff;
    background: linear-gradient(80deg, #405de6, #5851db, #833ab4);
    border: none;
    border-radius: 30px;
    display: block;
    margin-top: 3rem;
    &:hover {
      transform: scale(1.04);
      transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
    margin-left: auto;
    margin-right: auto;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
    margin-bottom: 20px;
    @media (max-width: 600px) {
        width: 90%;
    }
`;

const LinkToHome = styled(Link)`
    text-decoration: none;
    width: 50%;
    max-width: 800px;
`

const FounderContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const ImageContainer = styled.div`
    margin-top: 20px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    img {
        width: 100%;
        object-fit: fill;
        object-position: 50% 50%;
    }
`

const SignatureContainer = styled.div`
    height: 80px;
    overflow: hidden;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    img {
        height: 100%;
        width: auto;
        object-fit: fill;
        object-position: 50% 50%;
    }
`


const About = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        document.title = 'CaseBot | Privacy Policy'
      }, []);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const handleGoHome = () => {
        setAnimate(true);
        const timer = setTimeout(() => {}, 500);
        return () => clearTimeout(timer);
    }

    return (
        <>
            <BigContainer animate = {animate}>
                <TitleText>About CaseBot</TitleText>
                <Message>I built CaseBot from scratch with the money I saved from doing Instacart deliveries. To most, CaseBot is a direct-to-consumer phone accessory startup that leverages novel text-to-image AI tools for product customization. But to me, it has always been much more, because of what I sacrificed to bring it to life. To me, CaseBot is the bug fixes; the all-nighters; the days and nights spent on friends' floors to save on rent; the self-doubt, and the optimism that carried me through; the good friends that lifted me along the way. I love CaseBot, not in spite of, but because of the challenges I overcame in creating it. It is my sincere hope you will love it too.</Message>
                <Message>CaseBot exists to let you express yourself more vividly and authentically. Whenever you create something beautiful—something that didn't exist before—you're adding to our tradition.</Message>
                <FounderContainer>
                    <ImageContainer>
                        <img src={AboutImage} alt="Image of the founder" />
                    </ImageContainer>
                    <SignatureContainer>
                        <img src={Signature} alt="Founder's signature" />
                    </SignatureContainer>
                </FounderContainer>
                <LinkToHome to="/" onClick={handleGoHome}>
                    <Button>Return Home</Button>
                </LinkToHome>
            </BigContainer>
        </>
    )
}

export default About;
