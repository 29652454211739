import React, { useContext, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import ItemsContext from '../contexts/ItemsContext';
import ImageContext from '../contexts/ImageContext';
import LastPageContext from '../contexts/LastPageContext';
import Cookies from 'js-cookie';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`

const BigContainer = styled.div`
    margin-top: 2.5rem;
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - 60px);
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    @media (max-width: 600px) {
        width: 85%;
    }
`

const TitleText = styled.h1`
    font-family: 'Righteous';
    font-size: 50px;
    margin-bottom: 10px;
    box-sizing: border-box;
    text-align: center;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 36px;
    }
`;

const Message = styled.div`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`

const MailLink = styled.a`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.6s;
    opacity: 0;
    text-decoration: none;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`

const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    width: 50%;
    max-width: 800px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem;
    color: #ffffff;
    background: linear-gradient(80deg, #405de6, #5851db, #833ab4);
    border: none;
    border-radius: 30px;
    display: block;
    margin-top: 3rem;
    &:hover {
      transform: scale(1.04);
      transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
    margin-left: auto;
    margin-right: auto;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
    margin-bottom: 20px;
    @media (max-width: 600px) {
        width: 90%;
    }
`;

const LinkToHome = styled(Link)`
    text-decoration: none;
    width: 50%;
    max-width: 800px;
    margin-bottom: 20px;
`


const CheckoutSuccess = () => {
    const { imageUrl, setImageUrl } = useContext(ImageContext);
    const { items, setItems } = useContext(ItemsContext);
    const { lastPage, setLastPage } = useContext(LastPageContext);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        document.title = 'CaseBot | Success!'
      }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
            Cookies.remove('lastPage', { domain: cookieDomain });
            Cookies.remove('items', { domain: cookieDomain });
            Cookies.remove('imageUrl', { domain: cookieDomain });
            setItems([]);
            setLastPage('');
            setImageUrl('');
            localStorage.clear();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, []);

    const handleGoHome = () => {
        setAnimate(true);
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
        Cookies.remove('lastPage', { domain: cookieDomain });
        Cookies.remove('items', { domain: cookieDomain });
        Cookies.remove('imageUrl', { domain: cookieDomain });
        setItems([]);
        setLastPage('');
        setImageUrl('');
        localStorage.clear();
        const timer = setTimeout(() => {}, 500);
        return () => clearTimeout(timer);
    }

    return (
        <>
            <BigContainer animate = {animate}>
                <TitleText>Success!</TitleText>
                <Message>Your order was placed successfully. We've sent a message with confirmation details to the email you provided at checkout. You can close this window, or create a new design if you'd like.</Message>
                <Message>Feel free to message <MailLink href="mailto:support@casebot.io">support@casebot.io</MailLink> with any additional questions you have.</Message>
                <LinkToHome to="/" onClick={handleGoHome}>
                    <Button>Return Home</Button>
                </LinkToHome>
            </BigContainer>
        </>
    )
}

export default CheckoutSuccess;
