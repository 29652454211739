import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import styled, { css, keyframes } from 'styled-components';
import Cookies from 'js-cookie';
import ImageContext from '../contexts/ImageContext';
import ItemsContext from '../contexts/ItemsContext';
import RecentCaseContext from '../contexts/RecentCaseContext';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const goAway = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .2;
    transform: scale(.95);
  }
`

const CartContainer = styled.div`
  margin-top: 20px;
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 60px);
  animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
`;

const ProductImage = styled.img`
  height: 100%;
  max-height: 150px;
  object-fit: cover;
  margin-right: 16px;
  @media (max-width: 600px) {
    max-height: 80px;
  }
`;

const ItemDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;  
  justify-content: flex-start;
`;

const ItemName = styled.h3`
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1.5rem;
  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

const QuantityBox = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ItemQuantity = styled.p`
  margin: 5px 0;
  font-family: 'Poppins', sans-serif;
`;

const QuantityButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
  }
`

const IncrementButton = styled.button`
  margin-left: .24rem;
  margin-right: .5rem;
  border-radius: 50%;
  height: 20px;
  font-weight: bold;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  border: 1px solid #000000;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  padding: 0px;
  &:hover {
    transform: scale(1.2);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    margin-right: 0;
    &:hover {
    transform: scale(1);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
  }
  }
`

const DecrementButton = styled.button`
  margin-left: .5rem;
  margin-right: .24rem;
  border-radius: 50%;
  height: 20px;
  font-weight: bold;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  border: 1px solid #000000;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  padding: 0px;
  &:hover {
    transform: scale(1.2);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    margin-left: 0;
    &:hover {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(1);
        transition: all 0.05s ease-in-out;
    }
  }
`

const ItemPrice = styled.p`
  margin: 5px 0;
  font-family: 'Poppins', sans-serif;
`;

const IconWrapper = styled.div`
  margin-right: 2rem;
  &:hover {
    color: red;
    transform: scale(1.2);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    color: red;
    transform: scale(.9);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    margin-right: 10px;
    &:hover {
      color: red;
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
    &:active {
      color: red;
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
  }
`

const EmptyText = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 14px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const AddProductContainer = styled.div`
  width: 20rem;
  min-width: 120px;
  margin-right: ${({ emptiness }) => (emptiness ? '0px' : '1rem')};
`

const AddCaseSelect = styled(Select)`
  box-sizing: border-box;
  width: ${(props) => props.width};
  margin-right: ${({ emptiness }) => (emptiness ? '0px' : '1rem')};
`

const CheckoutButtonContainer = styled.div`
  width: 20rem;
  min-width: 120px;
  margin-right: 1rem;
`

const CheckoutButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  border: none;
  height: 50px;
  border-radius: 30px;
  font-size: 16px;
  color: #ffffff;
  background: linear-gradient(80deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  font-weight: bold;
  box-sizing: border-box;
  margin-left: 1rem;
  &:hover {
      transform: scale(1.05);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Cart = () => {
  const [animate, setAnimate] = useState(false);
  const [total, setTotal] = useState(0);
  const [doesCartHaveItems, setDoesCartHaveItems] = useState(true);
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);
  const { imageUrl, setImageUrl } = useContext(ImageContext);
  const { items, setItems } = useContext(ItemsContext);
  const { recentCase, setRecentCase } = useContext(RecentCaseContext);
  const navigate = useNavigate();
  const [selectOptions, setSelectOptions] = useState([
    { value: 'new', label: 'New design' },
    { value: 'saved', label: 'From favorites' },
    { value: 'lastUsed', label: 'Last added to cart' },
  ]);

  useEffect(() => {
    if (recentCase) {
      setSelectOptions([
        { value: 'new', label: 'New design' },
        { value: 'saved', label: 'From favorites' },
        { value: 'lastUsed', label: 'Last added to cart' },
      ]);
    } else if (!recentCase) {
      setSelectOptions([
        { value: 'new', label: 'New design' },
        { value: 'saved', label: 'From favorites' }
      ]);
    }
  }, [recentCase]);

  const selectStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid #fd1d1d',
      borderRadius: '30px',
      boxShadow: 'none',
      boxSizing: 'border-box',
      height: '50px',
      borderRadius: '30px',
      appearance: 'none',
      backgroundColor: 'white',
      textAlign: 'center',
      '&:hover': {
        transform: 'scale(1.05)'
      }
    }),
    option: (base) => ({
      ...base,
      color: '#000000',
      fontFamily: "'Poppins', sans-serif",
      fontSize: '16px'
    }),
    placeholder: (base) => ({
      ...base,
      color: '#000000',
      fontFamily: "'Poppins', sans-serif",
      fontSize: windowWidth <= 600 ? '14px': '16px',
    })
  };
  
  useEffect(() => {
    document.title = 'CaseBot | Cart';
  }, []);

  const updateWindowWidth = () => {
    setWindowWidth(document.documentElement.clientWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    }
  }, []);

  const handleRemove = (theLoserItemWeAreDumping) => {
    const newCart = items.filter(item => !(item.variantId === theLoserItemWeAreDumping.variantId && item.imageUrl === theLoserItemWeAreDumping.imageUrl));
    setItems(newCart);
    const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
    Cookies.set('items', JSON.stringify(newCart), { expires: 7, domain: cookieDomain });
    localStorage.removeItem(`${theLoserItemWeAreDumping.variantId}_${theLoserItemWeAreDumping.imageUrl}`);
  };

  const handleDecrement = (variantId, imageUrl) => {
    const changedItemIndex = items.findIndex(item => (item.variantId === variantId && item.imageUrl === imageUrl));
    if (changedItemIndex !== -1 && items[changedItemIndex].quantity > 1) {
      const newCart = items.slice();
      newCart[changedItemIndex] = {
        ...items[changedItemIndex],
        quantity: items[changedItemIndex].quantity - 1,
      };
      setItems(newCart);
      const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
      Cookies.set('items', JSON.stringify(newCart), { expires: 7, domain: cookieDomain });
    }
  };

  const handleIncrement = (variantId, imageUrl) => {
    const changedItemIndex = items.findIndex(item => (item.variantId === variantId && item.imageUrl === imageUrl));
    if (changedItemIndex !== -1 && items[changedItemIndex].quantity < 99) {
      const newCart = items.slice();
      newCart[changedItemIndex] = {
        ...items[changedItemIndex],
        quantity: items[changedItemIndex].quantity + 1,
      };
      setItems(newCart);
      const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
      Cookies.set('items', JSON.stringify(newCart), { expires: 7, domain: cookieDomain });
    }
  }

  useEffect(() => {
    const newTotal = 46 * items.reduce((acc, item) => {return acc + item.quantity}, 0);
    setTotal(newTotal);
  }, [items])

  useEffect(() => {
    if (items.length === 0) {
      setDoesCartHaveItems(false);
    } else if (items.length !== 0) {
      setDoesCartHaveItems(true);
    }
  }, [items]);

  const handleAddCase = (selectedOption) => {
    const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
    if (selectedOption.value === 'new') {
      setAnimate(true);
      Cookies.set('lastPage', 'landing', { expires: 7, domain: cookieDomain });
      const timer = setTimeout(() => {
        navigate('/');
      }, 500);
      return () => clearTimeout(timer);
    } else if (selectedOption.value === 'saved') {
      setAnimate(true);
      Cookies.set('lastPage', 'savedImages', { expires: 7, domain: cookieDomain });
      const timer = setTimeout(() => {
        navigate('/favorites');
      }, 500);
      return () => clearTimeout(timer);
    } else if (selectedOption.value === 'lastUsed') {
      setAnimate(true);
      Cookies.set('lastPage', 'ordering', { expires: 7, domain: cookieDomain });
      const timer = setTimeout(() => {
        navigate(`/case?case=${recentCase}`);
      }, 500);
      return () => clearTimeout(timer);
    }
  }

  const checkout = async () => {
    const checkoutUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.casebot.io/api/checkout' : 'http://localhost:3001/api/checkout';
    try {
        const response = await fetch(checkoutUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
        })
        .then(res => res.json())
        .then(data => {
          window.location.href = data.stripeUrl;
        })

    } catch {
        console.error('server error :( de chekout canot be happen');
    }
  }

  const iconStyles = {
    fontSize: '2rem'
  }

  return (
    <div>
      <CartContainer animate={animate}>
        {items.map(item => (
          <CartItem key={`${item.variantId}_${item.imageUrl}`}>
            <ProductImage src={localStorage.getItem(`${item.variantId}_${item.imageUrl}`)} />
            <ItemDetails>
              <ItemName>{item.size} Case</ItemName>
              <QuantityBox>
                <ItemQuantity>QTY: <strong>{item.quantity}</strong></ItemQuantity>
                <QuantityButtonsBox>
                  <DecrementButton onClick={() => handleDecrement(item.variantId, item.imageUrl)}><span>–</span></DecrementButton>
                  <IncrementButton onClick={() => handleIncrement(item.variantId, item.imageUrl)}><span>+</span></IncrementButton>
                </QuantityButtonsBox>
              </QuantityBox>
              <ItemPrice>${46 * item.quantity}</ItemPrice>
            </ItemDetails>
            <IconWrapper>
              <DeleteOutlineIcon onClick={() => handleRemove(item)} style={iconStyles}></DeleteOutlineIcon>
            </IconWrapper>
          </CartItem>
        ))}
        {!doesCartHaveItems && (
          <EmptyText>Your cart is empty!</EmptyText>
        )}
        <ButtonContainer>
            <AddProductContainer emptiness={!doesCartHaveItems}>
              <AddCaseSelect
                options={selectOptions}
                styles={selectStyles}
                placeholder="Add Case"
                value={null}
                isSearchable={false}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                width= {doesCartHaveItems ? '100%' : null}
                onChange={handleAddCase}
                emptiness={!doesCartHaveItems}
              />
            </AddProductContainer>
          {doesCartHaveItems && (
            <CheckoutButtonContainer>
              <CheckoutButton onClick={checkout}>Checkout: ${total}</CheckoutButton>
            </CheckoutButtonContainer>
          )}
        </ButtonContainer>
      </CartContainer>
    </div>
  );
};

export default Cart;
