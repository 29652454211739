import Home from "./pages/Home";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AddReview from "./pages/AddReview";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import FAQ from "./pages/FAQ";
import About from "./pages/About";
import Partners from "./pages/Partners";
import Landing from "./pages/Landing";
import Customize from "./pages/Customize";
import Cart from "./pages/Cart";
import Ordering from "./pages/Ordering";
import SavedImages from "./pages/SavedImages";
import SelectSavedImages from "./pages/SelectSavedImages";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ImageContext from "./contexts/ImageContext";
import GenerateErrorContext from "./contexts/GenerateErrorContext";
import PromptContext from "./contexts/PromptContext";
import SavedImagesContext from "./contexts/SavedImagesContext";
import ItemsContext from "./contexts/ItemsContext";
import LastPageContext from "./contexts/LastPageContext";
import CaseImagesContext from "./contexts/CaseImagesContext";
import PrintContext from "./contexts/PrintContext";
import SelectSavedIntentContext from "./contexts/SelectSavedIntentContext";
import RecentCaseContext from "./contexts/RecentCaseContext";
import PngSequenceContext from "./contexts/PngSequenceContext";
import '@fortawesome/fontawesome-svg-core/styles.css';
import Cookies from 'js-cookie';

const App = () => {
  const [imageUrl, setImageUrl] = React.useState(null);
  const [generateErrorStatus, setGenerateErrorStatus] = React.useState(false); // not using this, need to delete (although we should handle errors better in the customoize component)
  const [prompt, setPrompt] = React.useState('');
  const [savedImages, setSavedImages] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [lastPage, setLastPage] = React.useState('prompt');
  const [selectSavedIntent, setSelectSavedIntent] = React.useState(''); // not using this, but it is still referenced in the code somewhere--no longer necessary
  const [caseImages, setCaseImages] = React.useState(null);
  const [print, setPrint] = React.useState(null);
  const [recentCase, setRecentCase] = React.useState(null);
  const [pngSequence, setPngSequence] = React.useState(null);

  useEffect(() => {
    if (!imageUrl && Cookies.get('imageUrl')) {
      setImageUrl(Cookies.get('imageUrl'));
    }
    if (items.length === 0 && Cookies.get('items')) {
      setItems(JSON.parse(Cookies.get('items')));
    }
    if (savedImages.length === 0 && Cookies.get('savedImages')) {
      setSavedImages(JSON.parse(Cookies.get('savedImages')));
    }
  }, []);
  
  return (
    <ImageContext.Provider value = {{ imageUrl, setImageUrl}}>
      <GenerateErrorContext.Provider value = {{ generateErrorStatus, setGenerateErrorStatus}}>
        <PromptContext.Provider value = {{ prompt, setPrompt }}>
          <SavedImagesContext.Provider value = {{ savedImages, setSavedImages }}>
            <ItemsContext.Provider value = {{ items, setItems }}>
              <LastPageContext.Provider value = {{ lastPage, setLastPage }}>
                <SelectSavedIntentContext.Provider value = {{ selectSavedIntent, setSelectSavedIntent }}>
                  <CaseImagesContext.Provider value = {{ caseImages, setCaseImages }}>
                    <PrintContext.Provider value = {{ print, setPrint }}>
                      <RecentCaseContext.Provider value = {{ recentCase, setRecentCase }}>
                        <PngSequenceContext.Provider value = {{ pngSequence, setPngSequence }}>
                          <Router>
                            <Navbar />
                            <Routes>
                              <Route path="/checkoutsuccess" element={<CheckoutSuccess/>}/>
                              <Route path="privacy" element={<PrivacyPolicy/>}/>
                              <Route path="addreview" element={<AddReview/>}/>
                              <Route path="contact" element={<Contact/>}/>
                              <Route path="terms" element={<Terms/>}/>
                              <Route path="faq" element={<FAQ/>}/>
                              <Route path="about" element={<About/>}/>
                              <Route path="partners" element={<Partners/>}/>
                              <Route path="cart" element={<Cart/>}/>
                              <Route path="customize" element={<Customize/>}/>
                              <Route path="favorites" element={<SavedImages/>}/>
                              <Route path="selectfavorites" element={<SelectSavedImages/>}/>
                              <Route path="case" element={<Ordering/>}/>
                              <Route path="/" element={<Landing/>}/>
                            </Routes>
                            <Footer />
                          </Router>
                        </PngSequenceContext.Provider>
                      </RecentCaseContext.Provider>
                    </PrintContext.Provider>
                  </CaseImagesContext.Provider>
                </SelectSavedIntentContext.Provider>
              </LastPageContext.Provider>
            </ItemsContext.Provider>
          </SavedImagesContext.Provider>
        </PromptContext.Provider>
      </GenerateErrorContext.Provider>
    </ImageContext.Provider>
  );
};

export default App;