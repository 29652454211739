import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import Cookies from 'js-cookie';
import ImageContext from '../contexts/ImageContext';
import GenerateErrorContext from '../contexts/GenerateErrorContext';
import SavedImagesContext from '../contexts/SavedImagesContext';
import SelectSavedIntentContext from '../contexts/SelectSavedIntentContext';
import '../css/fonts.css';


const goAway = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .2;
    transform: scale(.95);
  }
`

const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
`

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 42px;
  margin-bottom: 0px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 27px;
  }
  @media (max-width: 470px) {
    font-size: 28px;
  }
`;

const Text1 = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 3.5rem;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 2rem;
  }
`

const Text2 = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const ImagesContainer = styled.div`
  width: 120%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 18vw));
  grid-gap: 10px;
  overflow: hidden;
  margin-bottom: 3rem;
  justify-content: center;
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 18vw));
  }
`

const ImageContainer = styled.div`
  position: relative;
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
  &:hover {
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    &:hover {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
    &:active {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
  }
`

const ImageText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5em;
  text-align: center;
  visibility: hidden;
  ${ImageContainer}:hover & {
    visibility: visible;
    transition: all 0.4s ease-in-out;
  }
  font-family: 'Poppins', sans-serif;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${ImageContainer}:hover & {
    filter: blur(10px);
    transition: all 0.05s ease-in-out;
  }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 30%;
    align-items: center;
    margin-bottom: 20px;
`;

const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    padding: 0.5rem 1rem;
    border: none;
    height: 50px;
    border-radius: 30px;
    font-size: 15px;
    width: 10rem;
    min-width: 132px;
    &:hover {
        transform: scale(1.05);
        transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
`;

const AloneGreenButton = styled.button`
  margin-bottom: 20px;
  background: linear-gradient(80deg, #405de6, #5851db, #833ab4);
  color: white;
  width: 30%;
  font-size: 17px;
  min-width: calc(264px + 1.6rem);
  font-family: 'Poppins', sans-serif;
  padding: 0.5rem 1rem;
  border: none;
  height: 50px;
  border-radius: 30px;
  font-size: 15px;
  width: 10rem;
  min-width: 132px;
  &:hover {
      transform: scale(1.05);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
`;

const GreenButton = styled(Button)`
    margin-left: .8rem;
    background: linear-gradient(80deg, #405de6, #5851db, #833ab4);
    color: white;
`;

const RedButton = styled(Button)`
    margin-right: .8rem;
    background: linear-gradient(80deg, #c13584, #e1306c, #fd1d1d);
    color: white;
`;

const SavedImages = ({handlePageChange}) => {
    const { savedImages, setSavedImages } = useContext(SavedImagesContext);
    const { imageUrl, setImageUrl } = useContext(ImageContext);
    const { selectSavedIntent, setSelectSavedIntent } = useContext(SelectSavedIntentContext);
    const [animate, setAnimate] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
        document.title = 'CaseBot | Saved Images';
      }, []);

    useEffect(() => {
      window.scrollTo(0,0);
    }, []);

    function handleImageClick(url) {
      setAnimate(true);
      const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
      Cookies.set('lastPage', 'ordering', { expires: 7 , domain: cookieDomain });
      const imageKey = url.split('/').pop();
      Cookies.set('imageUrl', url, { expires: 7 , domain: cookieDomain });
      const timer = setTimeout(() => {
        navigate(`?case=${imageKey}`);
      }, 500);
      return () => clearTimeout(timer);
    }

    function addImage() {
      setAnimate(true);
      const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
      Cookies.set('lastPage', 'landing', { expires: 7 , domain: cookieDomain });
      const timer = setTimeout(() => {
        handlePageChange('landing');
      }, 500);
      return () => clearTimeout(timer);
    }

    function cleanupImages() {
      setAnimate(true);
      setSelectSavedIntent('cleanup');
      const timer = setTimeout(() => {
        handlePageChange('selectSavedImages');
      }, 500);
      return () => clearTimeout(timer);
    }

    return (
      <Container animate={animate}>
          <Title>Favorites</Title>
          <Text1>You can favorite up to 12 designs. If you clear your cookies/cache, these will disappear.</Text1>
          <ImagesContainer>
              {savedImages.map((url, index) => (
                  <ImageContainer key={url}>
                    <Image src={url} onClick={() => handleImageClick(url)}></Image>
                    <ImageText onClick={() => handleImageClick(url)}>Use this</ImageText>
                  </ImageContainer>
              ))}
          </ImagesContainer>
          {savedImages.length > 0 ? (
            <ButtonContainer>
              <RedButton onClick={() => cleanupImages()}>Edit list</RedButton>
              <GreenButton onClick={() => addImage()}>New image</GreenButton>
            </ButtonContainer>
          ) : (
            <AloneGreenButton onClick={() => addImage()}>New image</AloneGreenButton>
          )}
      </Container>
    )
}

export default SavedImages;