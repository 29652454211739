import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import FaceRetouchingNaturalOutlinedIcon from '@mui/icons-material/FaceRetouchingNaturalOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SnowboardingOutlinedIcon from '@mui/icons-material/SnowboardingOutlined';


const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const TextFooterBottom = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #cccccc;
  @media (max-width: 600px) {
    font-size: 10px;
  }
`

const ButtonFooterBottom = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #cccccc;
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0;
  @media (max-width: 600px) {
    font-size: 10px;
  }
`

const FooterBox = styled.footer`
  background: linear-gradient(to bottom right, #334cb2, #4640b0, #692d8d);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3.5rem;
`;

const FooterTitle = styled.h3`
  font-family: 'Righteous';
  font-size: 34px;
  margin-bottom: 1rem;
  color: #ddd;
  display: block;
`;

const FooterLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 20px;
  margin-bottom: 10px;
`

const FooterLink = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  display: block;
  color: #ddd;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:hover {
    text-decoration: underline;
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 18px;
    &:hover {
      text-decoration: none;
      transition: all 0.05s ease-in-out;
    }
  }
  @media (max-width: 350px) {
    font-size: 16px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
`;

const SocialLink = styled.a`
  margin: 0 0.5rem;
`;

const Footer = () => {
  const navigate = useNavigate();

  return (
    <FooterBox>
      <FooterTitle>Helpful Links</FooterTitle>
      <FooterLinkContainer>
        <FooterLink onClick={() => navigate('/contact')}><MailOutlinedIcon style={{ marginRight: '6px' }}/>Contact</FooterLink>
        <FooterLink onClick={() => navigate('/faq')}><InfoOutlinedIcon style={{ marginRight: '6px' }}/>FAQ</FooterLink>
        <FooterLink onClick={() => navigate('/about')}><SnowboardingOutlinedIcon style={{ marginRight: '6px' }}/>About Us (Me)</FooterLink>
        <FooterLink href="/careers"><WorkOutlineOutlinedIcon style={{ marginRight: '6px' }}/>Work for CaseBot</FooterLink>
        <FooterLink onClick={() => navigate('/partners')}><FaceRetouchingNaturalOutlinedIcon style={{ marginRight: '6px'}}/>Influencers and Partners</FooterLink>
        <FooterLink onClick={() => navigate('/privacy')}><FingerprintOutlinedIcon style={{ marginRight: '6px' }}/>Privacy Policy</FooterLink>
      </FooterLinkContainer>
      <FooterBottom>
        <TextFooterBottom>© CaseBot 2023</TextFooterBottom>
        <ButtonFooterBottom onClick={() => navigate('/terms')}>Terms of Service</ButtonFooterBottom>
      </FooterBottom>
      <SocialLinks>
        <SocialLink href="/instagram"></SocialLink>
        <SocialLink href="/tiktok"></SocialLink>
      </SocialLinks>
    </FooterBox>
  )

};

export default Footer;