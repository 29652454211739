import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`

const BigContainer = styled.div`
    margin-top: 2.5rem;
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    @media (max-width: 600px) {
        width: 85%;
    }
`

const TitleText = styled.h1`
    font-family: 'Righteous';
    font-size: 50px;
    margin-bottom: 20px;
    box-sizing: border-box;
    text-align: center;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

const Message = styled.div`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`

const MailLink = styled.a`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.6s;
    opacity: 0;
    text-decoration: none;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`

const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    width: 50%;
    max-width: 800px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem;
    color: #ffffff;
    background: linear-gradient(80deg, #405de6, #5851db, #833ab4);
    border: none;
    border-radius: 30px;
    display: block;
    margin-top: 3rem;
    &:hover {
      transform: scale(1.04);
      transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
    margin-left: auto;
    margin-right: auto;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
    margin-bottom: 10rem;
    @media (max-width: 600px) {
        width: 90%;
    }
`;

const LinkToHome = styled(Link)`
    text-decoration: none;
    width: 50%;
    max-width: 800px;
`


const Terms = () => {
    const [animate, setAnimate] = useState(false);

    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        document.title = 'CaseBot | Terms'
      }, []);

    const handleGoHome = () => {
        setAnimate(true);
        const timer = setTimeout(() => {}, 500);
        return () => clearTimeout(timer);
    }

    return (
        <>
            <BigContainer animate = {animate}>
                <TitleText>Terms of Service</TitleText>
                <Message>This is the boring word vomit that dictates the use of our site. Most don't need to and don't care to read through this. Regardless, the terms of use for this website, particularly with respect to its image generation capabilities, are as follows:</Message>
                <Message>1. You shall not use CaseBot's image generation service to create designs for your own commercial use. You shall not use CaseBot's image generation service to create designs for the commercial use of a third party. There are no restrictions on sharing images created with CaseBot's image generation service for non-commercial purposes.</Message>
                <Message>2. You shall not automate interaction with CaseBot's image generation service.</Message>
                <Message>3. You shall not attempt to bypass the rate limit on CaseBot's image generation service.</Message>
                <Message>4. You shall not attempt to generate illegal or explicit content using CaseBot's image generation service.</Message>
                <Message>5. Above all, you shall not be cringe in any way while using this website.</Message>
                <Message>Contact <MailLink href="mailto:support@casebot.io">support@casebot.io</MailLink> with any additional questions about CaseBot's terms of service.</Message>
                <LinkToHome to="/" onClick={handleGoHome}>
                    <Button>Return Home</Button>
                </LinkToHome>
            </BigContainer>
        </>
    )
}

export default Terms;
