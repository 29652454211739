import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import Slider from 'react-slick';
import Cookies from 'js-cookie';
import '../css/fonts.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import bubbles_800 from '../img/landing_images/bubbles_800_compressed.webp';
import bubbles_1200 from '../img/landing_images/bubbles_1200_compressed.webp';
import bubbles_1600 from '../img/landing_images/bubbles_1600_compressed.webp';
import bubbles_1920 from '../img/landing_images/bubbles_1920_compressed.webp';
import cat_800 from '../img/landing_images/cat_800_compressed.webp';
import cat_1200 from '../img/landing_images/cat_1200_compressed.webp';
import cat_1600 from '../img/landing_images/cat_1600_compressed.webp';
import cat_1920 from '../img/landing_images/cat_1920_compressed.webp';
import sunset_800 from '../img/landing_images/sunset_800_compressed.webp';
import sunset_1200 from '../img/landing_images/sunset_1200_compressed.webp';
import sunset_1600 from '../img/landing_images/sunset_1600_compressed.webp';
import sunset_1920 from '../img/landing_images/sunset_1920_compressed.webp';
import flowers_800 from '../img/landing_images/flowers_800_compressed.webp';
import flowers_1200 from '../img/landing_images/flowers_1200_compressed.webp';
import flowers_1600 from '../img/landing_images/flowers_1600_compressed.webp';
import flowers_1920 from '../img/landing_images/flowers_1920_compressed.webp';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
`;

const goAway = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .2;
    transform: scale(.95);
  }
`

const heroImageFadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const heroImageFadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
  margin-left: auto;
  margin-right: auto;
  width: 85%;
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-size: 36px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  box-sizing: border-box;
  text-align: left;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
  @media (max-width: 600px) {
    font-size: 32px;
    margin-top: 2rem;
  }
  @media (max-width: 470px) {
    font-size: 30px;
  }
`;

const Text1 = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding-left: 10px;
  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 0rem;
  }
`

const Text2 = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding-left: 10px;
  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 0rem;
  }
`

const Text3 = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 21px;
  text-color: black;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.7s;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`

const ContainerForAllHeroElements = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 2rem;
  gap: 3rem;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 0;
    gap: 0;
  }
`

const ContainerForTitleAndButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

const HeroImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  border-radius: 24px;
  transition: background 1s ease;
  margin-bottom: 2rem;
  height: 40vw;
  max-height: 1000px;
  min-width: 50%;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
    max-width: 500px;
    max-height: 500px;
    height: 100vw;
    min-width: 0;
    margin-top: 2rem;
  }
  @media (max-width: 800px) {
    width: 100%;
    max-width: 500px;
    max-height: 500px;
    height: 100vw;
    min-width: 0;
    margin-top: 0;
  }
  &::before {
    content: "";
    position: absolute;
    border-radius: 24px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ gradient }) => gradient || 'linear-gradient(135deg, #FFA73F 3.11%, #ECE02B 94.01%)'};
    transition: opacity 0.5s ease;
    z-index: -1;
  }
`

const HeroImageText = styled.div`
  font-family: 'Righteous';
  font-size: 22px;
  white-space: nowrap;
  margin-bottom: 1.3rem;
  margin-top: -2rem;
  @media (max-width: 600px) {
    font-size: 22px;
  }
`

const HeroImage = styled.div`
  display: flex;
  margin-top: -2rem;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  height: 100%;
  width: 100%;
  @media (max-width: 1000px) {
    max-height: 1000px;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 800px) {
    max-height: 800px;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 100%;
    width: 100%;
  }
  & > img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`

const ImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
`

const CarouselImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${ImageContainer}:hover & {
    transition: all 0.05s ease-in-out;
  }
`

const ButtonContainerForAnimation = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.7s;
`

const CreateButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 300px;
  border: none;
  height: 50px;
  border-radius: 30px;
  font-size: 16px;
  color: #ffffff;
  background: linear-gradient(80deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  transform: scale(1);

  &:hover {
      transform: scale(1.05);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const TrendingImagesButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 400px;
  border: none;
  height: 50px;
  border-radius: 30px;
  font-size: 16px;
  color: #000000;
  background: none;
  border: 1px solid #fd1d1d;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  &:hover {
      transform: scale(1.05);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 300px;
  }
`;

const IconContainer1 = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`

const IconContainer2 = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 20px;
`

const StyledSlider = styled(Slider)`
  .slick-slide > div {
    margin: 0 5px;
  }
  margin-bottom: 50px;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.7s;
  overflow: visible;
`;

const ArrowButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  color: black;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  & > svg {
    width: 100%;
    height: auto;
  }
  &::before {
    display: none;
  }
  &:hover {
    color: black;
  }
`;


const Landing = () => {
  const [popularImages, setPopularImages] = useState([]);
  const [animate, setAnimate] = useState(false);
  const heroImageRef = useRef(null);
  const popularPrintsRef = useRef(null);
  const navigate = useNavigate();
  const [clientWidth, setClientWidth] = useState(window.innerWidth);
  const [heroImagesAreReady, setHeroImagesAreReady] = useState(false);
  const heroImagesAreReadyRef = useRef(heroImagesAreReady);
  const [heroCat, setHeroCat] = useState(
    document.documentElement.clientWidth <= 800 ? cat_800 :
    document.documentElement.clientWidth <= 1200 ? cat_1200 :
    document.documentElement.clientWidth <= 1600 ? cat_1600 :
    document.documentElement.clientWidth <= 1920 ? cat_1920 :
    cat_800
  );
  const [heroBubbles, setHeroBubbles] = useState(
    document.documentElement.clientWidth <= 800 ? bubbles_800 :
    document.documentElement.clientWidth <= 1200 ? bubbles_1200 :
    document.documentElement.clientWidth <= 1600 ? bubbles_1600 :
    document.documentElement.clientWidth <= 1920 ? bubbles_1920 :
    bubbles_800);
  const [heroSunset, setHeroSunset] = useState(
    document.documentElement.clientWidth <= 800 ? sunset_800 :
    document.documentElement.clientWidth <= 1200 ? sunset_1200 :
    document.documentElement.clientWidth <= 1600 ? sunset_1600 :
    document.documentElement.clientWidth <= 1920 ? sunset_1920 :
    sunset_800
  );
  const [heroFlowers, setHeroFlowers] = useState(
    document.documentElement.clientWidth <= 800 ? flowers_800 :
    document.documentElement.clientWidth <= 1200 ? flowers_1200 :
    document.documentElement.clientWidth <= 1600 ? flowers_1600 :
    document.documentElement.clientWidth <= 1920 ? flowers_1920 :
    flowers_800
  );
  const [heroImages, setHeroImages] = useState(null);
  const [currentHeroSlide, setCurrentHeroSlide] = useState(0);
  const heroSlides = [
    {
      gradient: "linear-gradient(135deg, #FFA73F 3.11%, #ECE02B 94.01%)",
      text: "DOODLE MODE",
      image: heroCat
    },
    {
      gradient: "linear-gradient(135deg, #800080 3.11%, #0000FF 94.01%)",
      text: "EXPRESSIONIST MODE",
      image: heroBubbles
    },
    {
      gradient: "linear-gradient(135deg, #FFA73F 3.11%, #ECE02B 94.01%)",
      text: "WATERCOLOR MODE",
      image: heroSunset
    },
    {
      gradient: "linear-gradient(135deg, #800080 3.11%, #0000FF 94.01%)",
      text: "STAINED GLASS MODE",
      image: heroFlowers
    }
  ];
  const [carouselSettings, setCarouselSettings] = useState(
    document.documentElement.clientWidth <= 600 ? {
      infinite: false,
      speed: 200,
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: true,
      nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
      prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
    } :
    document.documentElement.clientWidth <= 900 ? {
      infinite: false,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
      prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
    } :
    document.documentElement.clientWidth <= 1200 ? {
      infinite: false,
      speed: 200,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: true,
      nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
      prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
    } :
    document.documentElement.clientWidth > 1200 ? {
      infinite: false,
      speed: 200,
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: true,
      nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
      prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
    } :
    {
      infinite: false,
      speed: 200,
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: true,
      nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
      prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
  });

  // a function to load a single image into an image object
  const loadImage = async (src, useUniqueSrc) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const uniqueSrc = `${src}?t=${new Date().getTime()}`;
        if (useUniqueSrc) {
            img.src = uniqueSrc;
        } else {
            img.src = src;
        }
        img.onload = () => resolve(img);
        img.onerror = (error) => {
            reject(error);
        };
    });
  };

  // a function to load many images
  const loadHeroImages = async () => {
    const heroImagesToLoad = [heroCat, heroBubbles, heroSunset, heroFlowers];
    const imagePromises = heroImagesToLoad.map(src => loadImage(src));
    const loadedImages = await Promise.all(imagePromises);
    setHeroImages(loadedImages);
  }

  // loading our hero images so that we don't have to keep making requests for the resources
  useEffect(() => {
    loadHeroImages();
  }, []);

  // this useEffect is updating the heroImagesAreReady ref to reflect the current state of heroImagesAreReady; this is needed to feed current information about whether the images are ready or not to the effect that switches the slides
  useEffect(() => {
    heroImagesAreReadyRef.current = heroImagesAreReady;
  }, [heroImagesAreReady]);

  // here we are listening for updates in heroImages and setting heroImagesAreReady to true if the images are loaded and ready
  useEffect(() => {
    if (heroImages) {
      setHeroImagesAreReady(true);
    } else {
      setHeroImagesAreReady(false);
    };
  }, [heroImages]);

  // this effect listens for resizes and readjusts the carousel settings, the hero images, and the hero image width state variable
  useEffect(() => {
    const resetCarouselSettingsUponResize = () => {
        const newSettings = (
            document.documentElement.clientWidth <= 600 ? {
                infinite: false,
                speed: 200,
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
                nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
                prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
            } :
            document.documentElement.clientWidth <= 900 ? {
              infinite: false,
              speed: 200,
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
              nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
              prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
            } :
            document.documentElement.clientWidth <= 1200 ? {
              infinite: false,
              speed: 200,
              slidesToShow: 4,
              slidesToScroll: 1,
              dots: true,
              nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
              prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
            } :
            document.documentElement.clientWidth > 1200 ? {
              infinite: false,
              speed: 200,
              slidesToShow: 5,
              slidesToScroll: 1,
              dots: true,
              nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
              prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
            } :
            {
              infinite: false,
              speed: 200,
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
              nextArrow: <ArrowButton><KeyboardArrowRightIcon/></ArrowButton>,
              prevArrow: <ArrowButton><KeyboardArrowLeftIcon/></ArrowButton>,
            }
        );

        setCarouselSettings(newSettings);
    };

    const adjustHeroImagesForResize = () => {
      const newCat =
        document.documentElement.clientWidth <= 800 ? cat_800 :
        document.documentElement.clientWidth <= 1200 ? cat_1200 :
        document.documentElement.clientWidth <= 1600 ? cat_1600 :
        document.documentElement.clientWidth > 1600 ? cat_1920 :
        cat_800;
      const newBubbles =
        document.documentElement.clientWidth <= 800 ? bubbles_800 :
        document.documentElement.clientWidth <= 1200 ? bubbles_1200 :
        document.documentElement.clientWidth <= 1600 ? bubbles_1600 :
        document.documentElement.clientWidth > 1600 ? bubbles_1920 :
        bubbles_800;
      const newSunset =
        document.documentElement.clientWidth <= 800 ? sunset_800 :
        document.documentElement.clientWidth <= 1200 ? sunset_1200 :
        document.documentElement.clientWidth <= 1600 ? sunset_1600 :
        document.documentElement.clientWidth > 1600 ? sunset_1920 :
        sunset_800;
      const newFlowers =
        document.documentElement.clientWidth <= 800 ? flowers_800 :
        document.documentElement.clientWidth <= 1200 ? flowers_1200 :
        document.documentElement.clientWidth <= 1600 ? flowers_1600 :
        document.documentElement.clientWidth > 1600 ? flowers_1920 :
        flowers_800;

        setHeroCat(newCat);
        setHeroBubbles(newBubbles);
        setHeroSunset(newSunset);
        setHeroFlowers(newFlowers);
    }

    const handleAllResizeEvents = () => {
      resetCarouselSettingsUponResize();
      adjustHeroImagesForResize();
      setClientWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleAllResizeEvents);

    return () => {
        window.removeEventListener('resize', handleAllResizeEvents);
    }
  }, []);

  useEffect(() => {
    document.title = 'CaseBot | Design your phone case'
  }, []);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  // changes the hero image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHeroSlide((prevSlide) => (prevSlide + 1) % heroSlides.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // gets the popular images
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include'
    }
    const popularGensUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.casebot.io/api/populargens' : 'http://localhost:3001/api/populargens';
    async function getPopularImages() {
      await fetch(popularGensUrl, requestOptions)
      .then(res => {
        if (res.status === 500 || !res.ok) {
          throw new Error(res.status)
        }
        return res.json();
      })
      .then(data => {
        setPopularImages(data);
      })
      .catch(error => console.error(error));
    }

    getPopularImages();
  }, []);

  async function handleImageClick(url) {
    setAnimate(true);
    const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
    Cookies.set('lastPage', 'ordering', { expires: 7 , domain: cookieDomain });
    const imageKey = url.split('/').pop();
    const timer = setTimeout(() => {
      navigate(`/case?case=${imageKey}`)
    }, 500);

    return () => clearTimeout(timer);
  }

  function navigateToCustomize() {
    setAnimate(true);
    const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
    Cookies.set('lastPage', 'customize', { expires: 7 , domain: cookieDomain });
    const timer = setTimeout(() => {
        navigate('/customize');
    }, 500);

    return () => clearTimeout(timer);
  }

  return (
    <>
      <Wrapper animate={animate}>
        <Title>The phone case for your inner DaVinci.</Title>
        <ButtonContainerForAnimation><CreateButton onClick={() => navigateToCustomize()}><Text1>Create a case </Text1><IconContainer1><ArrowForwardIcon></ArrowForwardIcon></IconContainer1></CreateButton></ButtonContainerForAnimation>
        <ButtonContainerForAnimation><TrendingImagesButton onClick={() => popularPrintsRef.current.scrollIntoView({ behavior: 'smooth' })}><Text2>Check out trending designs </Text2><IconContainer2><ArrowDownwardIcon fontSize="inherit"></ArrowDownwardIcon></IconContainer2></TrendingImagesButton></ButtonContainerForAnimation>
        <Text3 ref={popularPrintsRef}>🌟 Popular Prints</Text3>
        <StyledSlider {...carouselSettings}>
            {popularImages.map((url, index) => (
                <ImageContainer key={url}>
                    <CarouselImage src={url} onClick={() => handleImageClick(url)}></CarouselImage>
                </ImageContainer>
            ))}
        </StyledSlider>
      </Wrapper>
    </>
  );
}

export default Landing;
