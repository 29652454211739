import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import SavedImagesContext from '../contexts/SavedImagesContext';
import ItemsContext from '../contexts/ItemsContext';
import SelectSavedIntentContext from '../contexts/SelectSavedIntentContext';
import Cookies from 'js-cookie';

const badgeTheme = createTheme({
    palette: {
        primary: {
            main: `#007BFF`
        },
        secondary: {
            main: `#007BFF`
        },
        error: {
            main: `#F5222D`
        },
    }
})

const Container = styled.div`
    height: 60px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 600px) {
        width: 85%;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    transition: top 0.2s ease-in-out;
    z-index: 500;
`;

const Left = styled.div`
    flex: 1;
    text-align: left;
    font-family: 'Righteous';
    font-size: 2rem;
    padding: 0;
    @media (max-width: 600px) {
        font-size: 1.3rem;
    }
`;

const HomeText = styled.span`
    font-family: 'Righteous';
    font-size: 2rem;
    color: black;
    &:hover {
        transform: scale(1.1);
        transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
    @media (max-width: 600px) {
        font-size: 1.3rem;
        &:hover {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
        }
        &:active {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
        }
    }
`

const Center = styled.div`
    flex: 1;
    text-align: center;
`;

const Right = styled.div`
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
`;

const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    margin: 0;
`

const HomeButton = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
`

const IconWrapperImages = styled.div`
    margin-right: .4rem;
    &:hover {
        transform: scale(1.2);
        transition: all 0.05s ease-in-out;
        z-index: 1;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
        z-index: 1;
    }
    @media (max-width: 600px) {
        &:hover {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
            z-index: 1;
        }
        &:active {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
            z-index: 1;
        }
    }
`

const IconWrapperCart = styled.div`
    &:hover {
        transform: scale(1.2);
        transition: all 0.05s ease-in-out;
        z-index: 1;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
        z-index: 1;
    }
    @media (max-width: 600px) {
        &:hover {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
            z-index: 1;
        }
        &:active {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
            z-index: 1;
        }
    }
`

const Navbar = ({handlePageChange}) => {
    const { savedImages, setSavedImages } = useContext(SavedImagesContext);
    const { items, setItems } = useContext(ItemsContext);
    const { selectSavedIntent, setSelectSavedIntent } = useContext(SelectSavedIntentContext);
    const [ noImagesAlert, setNoImagesAlert ] = useState(false);
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);

    const handleSavedImagesClick = () => {
        if (selectSavedIntent !== '') {
            return;
        }
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
        Cookies.set('lastPage', 'savedImages', { expires: 7 , domain: cookieDomain });
        setSelectSavedIntent('');
        navigate('/favorites');
    };

    const handleCartClick = () => {
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
        Cookies.set('lastPage', 'cart', { expires: 7 , domain: cookieDomain });
        setSelectSavedIntent('');
        navigate('/cart');
    };

    const handleHomeClick = () => {
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
        Cookies.set('lastPage', 'landing', { expires: 7 , domain: cookieDomain });
        setSelectSavedIntent('');
        navigate('/');
    }

    const handleCreateClick = () => {
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
        Cookies.set('lastPage', 'customize', { expires: 7 , domain: cookieDomain });
        setSelectSavedIntent('');
        navigate('/customize');
    }

    return (
        <Container>
            <Wrapper>
                <Left>
                    <HomeButton onClick = {handleHomeClick}>
                        <HomeText>CaseBot</HomeText>
                    </HomeButton>
                </Left>
                <Center></Center>
                <Right>
                    <Button onClick = {handleCreateClick}>
                        <IconWrapperImages>
                            <AddIcon style={{ color: 'black'}} />
                        </IconWrapperImages>
                    </Button>
                    <Button onClick = {handleSavedImagesClick}>
                        <IconWrapperImages>
                            <ThemeProvider theme={badgeTheme}>
                                <Badge badgeContent={savedImages.length > 0 ? (savedImages.length >= 12 ? 'MAX' : savedImages.length) : null} color={savedImages.length >= 12 ? 'error' : 'primary'}>
                                    <FavoriteIcon style={{ color: 'black'}} />
                                </Badge>
                            </ThemeProvider>
                        </IconWrapperImages>
                    </Button>
                    <Button onClick = {handleCartClick}>
                        <IconWrapperCart>
                            <ThemeProvider theme = {badgeTheme}>
                                <Badge badgeContent={items.length > 0 ? items.length : null} color='primary'>
                                    <ShoppingCartOutlinedIcon style={{ color: 'black'}} />
                                </Badge>
                            </ThemeProvider>
                        </IconWrapperCart>
                    </Button>
                </Right>
            </Wrapper>
        </Container>
    )
}

export default Navbar;