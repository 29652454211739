import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import Rating from 'react-rating';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`


const BigContainer = styled.div`
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 600px) {
        width: 85%;
    }
`

const TitleText = styled.h1`
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 24px;
    }
`;

const Label = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`

const TitleInput = styled.input`
    font-family: 'Lora', serif;
    font-size: 16px;
    width: 100%;
    max-width: 1000px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    border-color: ${({titleIs}) => (titleIs ? 'black' : '#f5222d')};
    &:focus {
        outline-color: ${({titleIs}) => (titleIs ? 'black' : '#f5222d')};
    }
`

const ContentInput = styled.textarea`
    font-family: 'Lora', serif;
    font-size: 16px;
    width: 100%;
    max-width: 1000px;
    height: 135px;
    border-radius: 20px;
    border: 1px solid;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    resize: none;
    margin-bottom: -3px;
    box-sizing: border-box;
    border-color: ${({contentIs}) => (contentIs ? 'black' : '#f5222d')};
    &:focus {
        outline-color: ${({contentIs}) => (contentIs ? 'black' : '#f5222d')};
    }
`

const NameInput = styled.input`
    font-family: 'Lora', serif;
    font-size: 16px;
    width: 100%;
    max-width: 1000px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    border-color: ${({nameIs}) => (nameIs ? 'black' : '#f5222d')};
    &:focus {
        outline-color: ${({nameIs}) => (nameIs ? 'black' : '#f5222d')};
    }
`

const EmailInput = styled.input`
    font-family: 'Lora', serif;
    font-size: 16px;
    width: 100%;
    max-width: 1000px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    border-color: ${({emailIs}) => (emailIs ? 'black' : '#f5222d')};
    &:focus {
        outline-color: ${({emailIs}) => (emailIs ? 'black' : '#f5222d')};
    }
`

const RequiredText = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    color: #f5222d;
    visibility: ${({textDisappear}) => (textDisappear ? 'hidden' : 'visible')};
`

const HolderOfZeButtons = styled.div`
    display: flex;
    justify-content: center;
`

const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    width: 50%;
    max-width: 800px;
    height: 50px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem;
    color: #ffffff;
    background: ${props => props.reviewIsValid ? 'linear-gradient(80deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)' : 'grey'};
    border: none;
    border-radius: 30px;
    display: block;
    margin-top: 16px;
    &:hover {
      transform: ${props => props.reviewIsValid ? 'scale(1.04)' : ''};
      transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: ${props => props.reviewIsValid ? 'scale(.95)' : ''};
        transition: all 0.05s ease-in-out;
    }
    margin-bottom: 20px;
    @media (max-width: 600px) {
        width: 90%;
    }
`;

const WaitingButton = styled.button`
    font-family: 'Poppins', sans-serif;
    width: 50%;
    max-width: 800px;
    height: 50px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem;
    color: #ffffff;
    background-color: grey;
    border: none;
    border-radius: 30px;
    display: block;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    @media (max-width: 600px) {
        width: 90%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
`

const Dot = styled.div`
    animation: ${pulse} 1s infinite;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
`;

const SuccessText = styled.div`
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
    box-sizing: border-box;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 24px;
    }
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
`

const GoHomeButton = styled.button`
    font-family: 'Poppins', sans-serif;
    width: 50%;
    max-width: 800px;
    height: 50px;
    font-size: 1rem;
    padding: 0.75rem;
    color: #ffffff;
    background: linear-gradient(80deg, #405de6, #5851db, #833ab4);
    border: none;
    border-radius: 30px;
    display: block;
    margin-top: 16px;
    &:hover {
      transform: scale(1.04);
      transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
`;

const Message = styled.div`
    font-family: 'Lora', serif;
    width: 80%;
    font-size: 20px;
    text-align: center;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`;

const SuccessDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const AddReview = () => {
    const [animate, setAnimate] = useState(false);
    const [reviewTitle, setReviewTitle] = useState('');
    const [reviewContent, setReviewContent] = useState('');
    const [reviewName, setReviewName] = useState('');
    const [reviewEmail, setReviewEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [rating, setRating] = useState(0);
    const [reviewIsValid, setReviewIsValid] = useState(false);
    const [waitingForYotpo, setWaitingForYotpo] = useState(false);
    const [postSuccess, setPostSuccess] = useState(false);
    const titleRef = useRef();
    const contentRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();

    useEffect(() => {
        document.title = 'CaseBot | Privacy Policy'
      }, []);

    useEffect(() => {
        if (reviewTitle.trim() !== '' && reviewContent.trim() !== '' && reviewName.trim() !== '' && validEmail && rating > 0) {
            setReviewIsValid(true);
        } else {
            setReviewIsValid(false);
        }
    }, [reviewTitle, reviewContent, reviewName, validEmail, rating])

    const handleTitleChange = (e) => {
        setReviewTitle(e.target.value);
    }

    const handleContentChange = (e) => {
        setReviewContent(e.target.value);
    }

    const handleNameChange = (e) => {
        setReviewName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setReviewEmail(e.target.value);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(e.target.value).toLowerCase())) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }

    const submitReview = async () => {
        if (reviewIsValid) {
            setWaitingForYotpo(true);
            const postUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.casebot.io/api/addreview' : 'http://localhost:3001/api/addreview';
            await fetch(postUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    display_name: reviewName,
                    email: reviewEmail,
                    review_content: reviewContent,
                    review_score: rating,
                    review_title: reviewTitle,
                })
            })
            .then(res => {
                res.json();
                setPostSuccess(true);
            })
            .catch(err => {
                console.error(err);
            });
        } else {
            return;
        }
    }

    const handleGoHome = () => {
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
        Cookies.set('lastPage', 'landing', { expires: 7, domain: cookieDomain });
    }

    return (
        <>
            {postSuccess ? (
                <>
                    <SuccessDiv>
                        <SuccessText>Success! :)</SuccessText>
                        <Message>Thank you :) it may take up to an hour for your review to be listed.</Message>
                    </SuccessDiv>
                    <Link to="/" style={{ textDecoration: 'none', marginTop: '12px' }} onClick={handleGoHome}>
                        <GoHomeButton>Return Home</GoHomeButton>
                    </Link>
                </>
            ) : (
                <BigContainer animate={animate}>
                    <TitleText>Add Review</TitleText>
                    <Label>Rating</Label>
                    <Rating
                        emptySymbol={<FontAwesomeIcon icon={regularStar} className="fa-2x" style={{ color: "#fdca40", fontSize: "30px"}}/>}
                        fullSymbol={<FontAwesomeIcon icon={solidStar} className="fa-2x" style={{ color: "#fdca40", fontSize: "30px"}}/>}
                        style={{ marginBottom: '4px' }}
                        initialRating={rating}
                        onChange={value => setRating(value)}
                    />
                    <RequiredText textDisappear={rating > 0}>Rating is required.</RequiredText>
                    <Label>Title</Label>
                    <TitleInput ref={titleRef} value={reviewTitle} onChange={handleTitleChange} titleIs={reviewTitle.trim() !== ''}></TitleInput>
                    <RequiredText textDisappear={reviewTitle.trim() !== ''}>Title is required.</RequiredText>
                    <Label>Review</Label>
                    <ContentInput ref={contentRef} value={reviewContent} onChange={handleContentChange} contentIs={reviewContent.trim() !== ''}></ContentInput>
                    <RequiredText textDisappear={reviewContent.trim() !== ''}>Review is required.</RequiredText>
                    <Label>Name</Label>
                    <NameInput ref={nameRef} value={reviewName} onChange={handleNameChange} nameIs={reviewName.trim() !== ''}></NameInput>
                    <RequiredText textDisappear={reviewName.trim() !== ''}>Name is required.</RequiredText>
                    <Label>Email</Label>
                    <EmailInput ref={emailRef} value={reviewEmail} onChange={handleEmailChange} emailIs={validEmail}></EmailInput>
                    <RequiredText textDisappear={validEmail}>Valid email is required.</RequiredText>
                    <HolderOfZeButtons>
                        {waitingForYotpo ? (
                            <WaitingButton><Dot/><Dot/><Dot/></WaitingButton>
                        ) : (
                            <Button onClick={submitReview} reviewIsValid={reviewIsValid}>Submit</Button>
                        )}
                    </HolderOfZeButtons>
                </BigContainer>
            )}
        </>
    )
}

export default AddReview;
