import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import styled, { css, keyframes } from 'styled-components';
import ImageContext from '../contexts/ImageContext';
import SavedImagesContext from '../contexts/SavedImagesContext';
import CaseImagesContext from '../contexts/CaseImagesContext';
import PrintContext from '../contexts/PrintContext';
import PromptContext from '../contexts/PromptContext';
import PngSequenceContext from '../contexts/PngSequenceContext';
import Cookies from 'js-cookie';
import '../css/fonts.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IosShareIcon from '@mui/icons-material/IosShare';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import placeholderpng from '../img/gradientBackground2.png';
import iphone7 from '../img/mockups/iphone_7.webp';
import iphone7Plus from '../img/mockups/iphone_7_plus.webp';
import iphone8 from '../img/mockups/iphone_8.webp';
import iphone8Plus from '../img/mockups/iphone_8_plus.webp';
import iphoneX from '../img/mockups/iphone_x.webp';
import iphoneXR from '../img/mockups/iphone_xr.webp';
import iphoneXS from '../img/mockups/iphone_xs.webp';
import iphoneXSMax from '../img/mockups/iphone_xs_max.webp';
import iphone11 from '../img/mockups/iphone_11.webp';
import iphone11Pro from '../img/mockups/iphone_11_pro.webp';
import iphone11ProMax from '../img/mockups/iphone_11_pro_max.webp';
import iphone12Mini from '../img/mockups/iphone_12_mini.webp';
import iphone12 from '../img/mockups/iphone_12.webp';
import iphone12Pro from '../img/mockups/iphone_12_pro.webp';
import iphone12ProMax from '../img/mockups/iphone_12_pro_max.webp';
import iphone13Mini from '../img/mockups/iphone_13_mini.webp';
import iphone13 from '../img/mockups/iphone_13.webp';
import iphone13Pro from '../img/mockups/iphone_13_pro.webp';
import iphone13ProMax from '../img/mockups/iphone_13_pro_max.webp';
import iphone14 from '../img/mockups/iphone_14.webp';
import iphone14Plus from '../img/mockups/iphone_14_plus.webp';
import iphone14Pro from '../img/mockups/iphone_14_pro.webp';
import iphone14ProMax from '../img/mockups/iphone_14_pro_max.webp';
import iphone15 from '../img/mockups/iphone_15.webp';
import iphone15Plus from '../img/mockups/iphone_15_plus.webp';
import iphone15Pro from '../img/mockups/iphone_15_pro.webp';
import iphone15ProMax from '../img/mockups/iphone_15_pro_max.webp';
import galaxyS10 from '../img/mockups/galaxy_s10.webp';
import galaxyS10E from '../img/mockups/galaxy_s10_e.webp';
import galaxyS10Plus from '../img/mockups/galaxy_s10_plus.webp';
import galaxyS20 from '../img/mockups/galaxy_s20.webp';
import galaxyS20Plus from '../img/mockups/galaxy_s20_plus.webp';
import galaxyS20Ultra from '../img/mockups/galaxy_s20_ultra.webp';
import galaxyS21 from '../img/mockups/galaxy_s21.webp';
import galaxyS21Plus from '../img/mockups/galaxy_s21_plus.webp';
import galaxyS21Ultra from '../img/mockups/galaxy_s21_ultra.webp';
import galaxyS22 from '../img/mockups/galaxy_s22.webp';
import galaxyS22Plus from '../img/mockups/galaxy_s22_plus.webp';
import galaxyS22Ultra from '../img/mockups/galaxy_s22_ultra.webp';
import galaxyS23 from '../img/mockups/galaxy_s23.webp';
import galaxyS23Plus from '../img/mockups/galaxy_s23_plus.webp';
import galaxyS23Ultra from '../img/mockups/galaxy_s23_ultra.webp';
import iphone7_small from '../img/mockups_small/iphone_7_small.webp';
import iphone7Plus_small from '../img/mockups_small/iphone_7_plus_small.webp';
import iphone8_small from '../img/mockups_small/iphone_8_small.webp';
import iphone8Plus_small from '../img/mockups_small/iphone_8_plus_small.webp';
import iphoneX_small from '../img/mockups_small/iphone_x_small.webp';
import iphoneXR_small from '../img/mockups_small/iphone_xr_small.webp';
import iphoneXS_small from '../img/mockups_small/iphone_xs_small.webp';
import iphoneXSMax_small from '../img/mockups_small/iphone_xs_max_small.webp';
import iphone11_small from '../img/mockups_small/iphone_11_small.webp';
import iphone11Pro_small from '../img/mockups_small/iphone_11_pro_small.webp';
import iphone11ProMax_small from '../img/mockups_small/iphone_11_pro_max_small.webp';
import iphone12Mini_small from '../img/mockups_small/iphone_12_mini_small.webp';
import iphone12_small from '../img/mockups_small/iphone_12_small.webp';
import iphone12Pro_small from '../img/mockups_small/iphone_12_pro_small.webp';
import iphone12ProMax_small from '../img/mockups_small/iphone_12_pro_max_small.webp';
import iphone13Mini_small from '../img/mockups_small/iphone_13_mini_small.webp';
import iphone13_small from '../img/mockups_small/iphone_13_small.webp';
import iphone13Pro_small from '../img/mockups_small/iphone_13_pro_small.webp';
import iphone13ProMax_small from '../img/mockups_small/iphone_13_pro_max_small.webp';
import iphone14_small from '../img/mockups_small/iphone_14_small.webp';
import iphone14Plus_small from '../img/mockups_small/iphone_14_plus_small.webp';
import iphone14Pro_small from '../img/mockups_small/iphone_14_pro_small.webp';
import iphone14ProMax_small from '../img/mockups_small/iphone_14_pro_max_small.webp';
import iphone15_small from '../img/mockups_small/iphone_15_small.webp';
import iphone15Plus_small from '../img/mockups_small/iphone_15_plus_small.webp';
import iphone15Pro_small from '../img/mockups_small/iphone_15_pro_small.webp';
import iphone15ProMax_small from '../img/mockups_small/iphone_15_pro_max_small.webp';
import galaxyS10_small from '../img/mockups_small/galaxy_s10_small.webp';
import galaxyS10E_small from '../img/mockups_small/galaxy_s10_e_small.webp';
import galaxyS10Plus_small from '../img/mockups_small/galaxy_s10_plus_small.webp';
import galaxyS20_small from '../img/mockups_small/galaxy_s20_small.webp';
import galaxyS20Plus_small from '../img/mockups_small/galaxy_s20_plus_small.webp';
import galaxyS20Ultra_small from '../img/mockups_small/galaxy_s20_ultra_small.webp';
import galaxyS21_small from '../img/mockups_small/galaxy_s21_small.webp';
import galaxyS21Plus_small from '../img/mockups_small/galaxy_s21_plus_small.webp';
import galaxyS21Ultra_small from '../img/mockups_small/galaxy_s21_ultra_small.webp';
import galaxyS22_small from '../img/mockups_small/galaxy_s22_small.webp';
import galaxyS22Plus_small from '../img/mockups_small/galaxy_s22_plus_small.webp';
import galaxyS22Ultra_small from '../img/mockups_small/galaxy_s22_ultra_small.webp';
import galaxyS23_small from '../img/mockups_small/galaxy_s23_small.webp';
import galaxyS23Plus_small from '../img/mockups_small/galaxy_s23_plus_small.webp';
import galaxyS23Ultra_small from '../img/mockups_small/galaxy_s23_ultra_small.webp';
import v00001 from '../img/pngSequence/00001.png';
import v00002 from '../img/pngSequence/00002.png';
import v00003 from '../img/pngSequence/00003.png';
import v00004 from '../img/pngSequence/00004.png';
import v00005 from '../img/pngSequence/00005.png';
import v00006 from '../img/pngSequence/00006.png';
import v00007 from '../img/pngSequence/00007.png';
import v00008 from '../img/pngSequence/00008.png';
import v00009 from '../img/pngSequence/00009.png';
import v00010 from '../img/pngSequence/00010.png';
import v00011 from '../img/pngSequence/00011.png';
import v00012 from '../img/pngSequence/00012.png';
import v00013 from '../img/pngSequence/00013.png';
import v00014 from '../img/pngSequence/00014.png';
import v00015 from '../img/pngSequence/00015.png';
import v00016 from '../img/pngSequence/00016.png';
import v00017 from '../img/pngSequence/00017.png';
import v00018 from '../img/pngSequence/00018.png';
import v00019 from '../img/pngSequence/00019.png';
import v00020 from '../img/pngSequence/00020.png';
import v00021 from '../img/pngSequence/00021.png';
import v00022 from '../img/pngSequence/00022.png';
import v00023 from '../img/pngSequence/00023.png';
import v00024 from '../img/pngSequence/00024.png';
import v00025 from '../img/pngSequence/00025.png';
import v00026 from '../img/pngSequence/00026.png';
import v00027 from '../img/pngSequence/00027.png';
import v00028 from '../img/pngSequence/00028.png';
import v00029 from '../img/pngSequence/00029.png';
import v00030 from '../img/pngSequence/00030.png';
import v00031 from '../img/pngSequence/00031.png';
import v00032 from '../img/pngSequence/00032.png';
import v00033 from '../img/pngSequence/00033.png';
import v00034 from '../img/pngSequence/00034.png';
import v00035 from '../img/pngSequence/00035.png';
import v00036 from '../img/pngSequence/00036.png';
import v00037 from '../img/pngSequence/00037.png';
import v00038 from '../img/pngSequence/00038.png';
import v00039 from '../img/pngSequence/00039.png';
import v00040 from '../img/pngSequence/00040.png';
import v00041 from '../img/pngSequence/00041.png';
import v00042 from '../img/pngSequence/00042.png';
import v00043 from '../img/pngSequence/00043.png';
import v00044 from '../img/pngSequence/00044.png';
import v00045 from '../img/pngSequence/00045.png';
import v00046 from '../img/pngSequence/00046.png';

const goAway = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .2;
    transform: scale(.95);
  }
`

const popupFadeOut = keyframes`
  from {
    opacity 1;
  }
  to {
    opacity: 0;
  }
`

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`

const BigBigBox = styled.div`
  animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    grid-template-columns: 100%;
  }
`;

const RightContainer = styled.div`
  position: relative;
  width: 80%;
  max-width: 800px;
  margin-top: 2rem;
  @media (max-width: 800px) {
    margin-right: auto;
    margin-left: auto;
    width: 88%;
    margin-top: 36px;
  }
`

const LeftContainer = styled.div`
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const BarForTwoCircleButtons = styled.div`
  margin-top: 10px;
  display: ${({render}) => (render ? 'flex' : 'none')};
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 800px) {
    width: 88%;
    margin-top: -160px;
    justify-content: flex-end;
  }
`

const TwoCircleButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const FavButton = styled.button`
  border: none;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 0px;
  background: #f0f0f0;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 0;
  color: ${({valid}) => (valid ? '#fd1d1d' : 'black')};
  &:hover {
    transform: scale(1.1);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
`

const ShareButton = styled.button`
  border: none;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: #f0f0f0;
  margin-left: 10px;
  color: black;
  box-sizing: border-box;
  padding: 0;
  &:hover {
    transform: scale(1.1);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 800px) {
    margin-left: 0px;
  }
`

const Wrapper = styled.div`
  padding: 0;
  margin-bottom: 20px;
`

const Label = styled.label`
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  display: block;
  margin-bottom: .7rem;
  margin-top: 2rem;
`

const LabelSpan = styled.span`
  font-family: 'Poppins', sans-serif;
  display: inline;
  font-size: 1.3rem;
  font-weight: bold;
  display: block;
  margin-bottom: .7rem;
`

const LabelNoMargin = styled.label`
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-bottom: .7rem;
`

const Label2 = styled.label`
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`

const PromptContainer = styled.div`
  padding: 10px;
  background: linear-gradient(80deg, #abd3ed, #cdf4dd);
  border-radius: 10px;
  border: 1px solid #999;
`

const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const GenerateButton = styled.button`
  --color1: ${({ valid }) => (valid ? '#405de6' : 'grey')};
  --color2: ${({ valid }) => (valid ? '#5851db' : 'grey')};
  --color3: ${({ valid }) => (valid ? '#833ab4' : 'grey')};
  --color4: ${({ valid }) => (valid ? '#c13584' : 'grey')};
  --color5: ${({ valid }) => (valid ? '#e1306c' : 'grey')};
  --color6: ${({ valid }) => (valid ? '#fd1d1d' : 'grey')};
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 200px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #ffffff;
  background: linear-gradient(80deg, var(--color1), var(--color2), var(--color3), var(--color4), var(--color5), var(--color6));
  border: none;
  border-radius: 30px;
  display: flex;
  height: 50px;
  transition: all .2s ease-in-out;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  &:hover {
    transform: scale(1.04);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
`;

const ImWorkingHereButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 200px;
  height: 50px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #ffffff;
  background: linear-gradient(80deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  border: none;
  border-radius: 30px;
  display: flex;
  transition: all .2s ease-in-out;
  align-items: center;
  justify-content: center;
  margin-top: 0;
`;

const TextArea = styled.textarea`
  border: 1px solid black;
  resize: none;
  width: 100%;
  height: 10rem;
  border-radius: 10px;
  padding: 5px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 3;
  @media (max-width: 800px) {
    height: 6rem;
  }
`

const SelectInputStyle = styled.select`
  border-radius: 30px;
  border: 1px solid #000000;
  height: 50px;
  appearance: none;
  padding: 0.5rem;
  font-size: 1rem;
  width: 10rem;
  max-width: 100px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  text-align-last: center;
  color: black;
  font-weight: bold;
  margin-right: 20px;
  background: #f0f0f0;
  @media (max-width: 600px) {
    font-size: 16px;
    margin-right: 15px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
    margin-right: 15px;
    max-width: 85px;
  }
`;

const SelectInput1 = styled.select`
  border-radius: 30px;
  border: 1px solid #000000;
  height: 50px;
  appearance: none;
  padding: 0.5rem;
  font-size: 1rem;
  width: 10rem;
  max-width: 100px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  text-align-last: center;
  color: black;
  margin-right: 20px;
  background: #f0f0f0;
  @media (max-width: 600px) {
    font-size: 16px;
    margin-right: 15px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
    margin-right: 15px;
    max-width: 85px;
  }
`;

const SelectInput2 = styled.select`
  border-radius: 30px;
  border: 1px solid #000000;
  height: 50px;
  appearance: none;
  padding: 0.5rem;
  font-size: 1rem;
  width: 10rem;
  max-width: 200px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  text-align-last: center;
  color: black;
  background: #f0f0f0;
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
    max-width: 145px;
  }
`;

const NumberInput = styled.input.attrs({ type: "number" })`
  font-family: 'Poppins', sans-serif;
  width: 20px;
  height: 20px;
  font-size: 1rem;
  padding: 0.75rem;
  text-align: center;
  border: 1px solid black;
  border-radius: 50%;
  font-weight: bold;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove the spinner for Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    margin-right: 0px;
  }
`

const IncrementButton = styled.button`
  border-radius: 50%;
  height: 50px;
  font-weight: bold;
  width: 50px;
  border: none;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: black;
  padding: 0;
  &:hover {
    transform: scale(1.2);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.95);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
`

const DecrementButton = styled.button`
  border-radius: 50%;
  height: 50px;
  font-weight: bold;
  width: 50px;
  border: none;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: black;
  padding: 0;
  &:hover {
    transform: scale(1.2);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

const ProceedButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #ffffff;
  background: ${({valid}) => (valid ? '  linear-gradient(80deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)' : 'grey')};
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.04);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    margin-top: 2.5rem;
  }
`;

const RestartText = styled.span`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const RestartButton = styled.button`
  background: none;
  border: none;
  margin-top: 16px;
  font-size: 14px;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  text-decoration: underline;
  &:hover {
    color: #fd1d1d;
    transform: scale(1.05);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    color: #fd1d1d;
    transform: scale(.97);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const Dot = styled.div`
    animation: ${pulse} 1s infinite;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
`;

const ErrorPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: linear-gradient(80deg, #c13584, #e1306c, #fd1d1d);
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #333;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`

const PopupText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const Customize = ({handlePageChange}) => {
    const { imageUrl, setImageUrl } = useContext(ImageContext);
    const { print, setPrint } = useContext(PrintContext);
    const { prompt, setPrompt } = useContext(PromptContext);
    const [selectedSize, setSelectedSize] = useState('iPhone 15');
    const [defaultPrint, setDefaultPrint] = useState(null);
    const [canvasWidth, setCanvasWidth] = useState(window.width > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400));
    const [showLeftContainer, setShowLeftContainer] = useState(window.innerWidth > 800 ? true : false);
    const [imWorkingHere, setImWorkingHere] = useState(false);
    const imWorkingHereRef = useRef(imWorkingHere);
    const selectedSizeRef = useRef(selectedSize);
    const [animationIsRunning, setAnimationIsRunning] = useState(false);
    const canvasRef = useRef(null);
    const sizeRef = useRef(null);
    const eventSourceRef = useRef(null);
    const navigate = useNavigate();
    const { caseImages, setCaseImages } = useContext(CaseImagesContext);
    const { pngSequence, setPngSequence } = useContext(PngSequenceContext);
    const [isRenderingDone, setIsRenderingDone] = useState(false);
    const { savedImages, setSavedImages } = useContext(SavedImagesContext);
    const [animate, setAnimate] = useState(false);
    
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showCopyPopup, setShowCopyPopup] = useState(false);
    const [showMaxFavoritePopup, setShowMaxFavoritePopup] = useState(false);

    const iPhoneSizes = ["iPhone 7", "iPhone 7 Plus", "iPhone 8", "iPhone 8 Plus", "iPhone X", "iPhone XR", "iPhone XS", "iPhone XS Max", "iPhone 11", "iPhone 11 Pro", "iPhone 11 Pro Max", "iPhone SE (2020)", "iPhone 12 mini", "iPhone 12", "iPhone 12 Pro", "iPhone 12 Pro Max", "iPhone 13 mini", "iPhone 13", "iPhone 13 Pro", "iPhone 13 Pro Max", "iPhone 14", "iPhone 14 Plus", "iPhone 14 Pro", "iPhone 14 Pro Max", "iPhone 15", "iPhone 15 Plus", "iPhone 15 Pro", "iPhone 15 Pro Max"];
    const samsungSizes = ["Galaxy S10", "Galaxy S10+", "Galaxy S10e", "Galaxy S20", "Galaxy S20 Plus", "Galaxy S20 Ultra", "Galaxy S21", "Galaxy S21 Plus", "Galaxy S21 Ultra", "Galaxy S22", "Galaxy S22 Plus", "Galaxy S22 Ultra", "Galaxy S23", "Galaxy S23 Plus", "Galaxy S23 Ultra"];
    const makes = ['iPhone', 'Samsung'];
    const [sizes, setSizes] = useState(iPhoneSizes);
    const [styles, setStyles] = useState(["No Style", "Watercolor", "Anime", "Leonid Afremov", "Expressionist", "Pop Art", "Van Gogh Starry Night", "Constructivist", "Psychedelic", "Doodle", "Stained Glass", "Grunge", "Art Nouveau", "Papercraft Collage", "Alma Thomas", "Abstract", "Vaporwave", "Pixel Art", "Illustration", "Photographic", "Pointillism", "Comic Book", "Oil Painting", "Children's Storybook", "Grayscale", "Colored Pencils", "Crayon Artwork"]); // mixed results with papercraft collage. I want to include ukiyo-e somehow
    const [make, setMake] = useState('iPhone');
    const [style, setStyle] = useState('No Style');
    const caseImagesForLoading = window.innerWidth > 800 ? {
      iPhone15: iphone15,
      iPhone15Plus: iphone15Plus,
      iPhone15Pro: iphone15Pro,
      iPhone15ProMax: iphone15ProMax,
      iPhone14: iphone14,
      iPhone14Plus: iphone14Plus,
      iPhone14Pro: iphone14Pro,
      iPhone14ProMax: iphone14ProMax,
      iPhone13: iphone13,
      iPhone13mini: iphone13Mini,
      iPhone13Pro: iphone13Pro,
      iPhone13ProMax: iphone13ProMax,
      iPhone12: iphone12,
      iPhone12mini: iphone12Mini,
      iPhone12Pro: iphone12Pro,
      iPhone12ProMax: iphone12ProMax,
      iPhone11: iphone11,
      iPhone11Pro: iphone11Pro,
      iPhone11ProMax: iphone11ProMax,
      iPhoneX: iphoneX,
      iPhoneXS: iphoneXS,
      iPhoneXSMax: iphoneXSMax,
      iPhoneXR: iphoneXR,
      iPhone7: iphone7,
      iPhone8: iphone8,
      iPhone7Plus: iphone7Plus,
      iPhone8Plus: iphone8Plus,
      samsungS10: galaxyS10,
      samsungS10e: galaxyS10E,
      samsungS10Plus: galaxyS10Plus,
      samsungS20: galaxyS20,
      samsungS20Plus: galaxyS20Plus,
      samsungS20Ultra: galaxyS20Ultra,
      samsungS21: galaxyS21,
      samsungS21Plus: galaxyS21Plus,
      samsungS21Ultra: galaxyS21Ultra,
      samsungS22: galaxyS22,
      samsungS22Plus: galaxyS22Plus,
      samsungS22Ultra: galaxyS22Ultra,
      samsungS23: galaxyS23,
      samsungS23Plus: galaxyS23Plus,
      samsungS23Ultra: galaxyS23Ultra
    } : {
      iPhone15: iphone15_small,
      iPhone15Plus: iphone15Plus_small,
      iPhone15Pro: iphone15Pro_small,
      iPhone15ProMax: iphone15ProMax_small,
      iPhone14: iphone14_small,
      iPhone14Plus: iphone14Plus_small,
      iPhone14Pro: iphone14Pro_small,
      iPhone14ProMax: iphone14ProMax_small,
      iPhone13: iphone13_small,
      iPhone13mini: iphone13Mini_small,
      iPhone13Pro: iphone13Pro_small,
      iPhone13ProMax: iphone13ProMax_small,
      iPhone12: iphone12_small,
      iPhone12mini: iphone12Mini_small,
      iPhone12Pro: iphone12Pro_small,
      iPhone12ProMax: iphone12ProMax_small,
      iPhone11: iphone11_small,
      iPhone11Pro: iphone11Pro_small,
      iPhone11ProMax: iphone11ProMax_small,
      iPhoneX: iphoneX_small,
      iPhoneXS: iphoneXS_small,
      iPhoneXSMax: iphoneXSMax_small,
      iPhoneXR: iphoneXR_small,
      iPhone7: iphone7_small,
      iPhone8: iphone8_small,
      iPhone7Plus: iphone7Plus_small,
      iPhone8Plus: iphone8Plus_small,
      samsungS10: galaxyS10_small,
      samsungS10e: galaxyS10E_small,
      samsungS10Plus: galaxyS10Plus_small,
      samsungS20: galaxyS20_small,
      samsungS20Plus: galaxyS20Plus_small,
      samsungS20Ultra: galaxyS20Ultra_small,
      samsungS21: galaxyS21_small,
      samsungS21Plus: galaxyS21Plus_small,
      samsungS21Ultra: galaxyS21Ultra_small,
      samsungS22: galaxyS22_small,
      samsungS22Plus: galaxyS22Plus_small,
      samsungS22Ultra: galaxyS22Ultra_small,
      samsungS23: galaxyS23_small,
      samsungS23Plus: galaxyS23Plus_small,
      samsungS23Ultra: galaxyS23Ultra_small
    }
    
    const sequenceImagesForLoading = {
      v00001: v00001,
      v00002: v00002,
      v00003: v00003,
      v00004: v00004,
      v00005: v00005,
      v00006: v00006,
      v00007: v00007,
      v00008: v00008,
      v00009: v00009,
      v00010: v00010,
      v00011: v00011,
      v00012: v00012,
      v00013: v00013,
      v00014: v00014,
      v00015: v00015,
      v00016: v00016,
      v00017: v00017,
      v00018: v00018,
      v00019: v00019,
      v00020: v00020,
      v00021: v00021,
      v00022: v00022,
      v00023: v00023,
      v00024: v00024,
      v00025: v00025,
      v00026: v00026,
      v00027: v00027,
      v00028: v00028,
      v00029: v00029,
      v00030: v00030,
      v00031: v00031,
      v00032: v00032,
      v00033: v00033,
      v00034: v00034,
      v00035: v00035,
      v00036: v00036,
      v00037: v00037,
      v00038: v00038,
      v00039: v00039,
      v00040: v00040,
      v00041: v00041,
      v00042: v00042,
      v00043: v00043,
      v00044: v00044,
      v00045: v00045,
      v00046: v00046,
    };

    const originalCanvasStyling = window.innerWidth > 800 ? {
        'top': '60',
        'left': '0',
        'marginLeft': 'auto',
        'marginRight': 'auto',
        'marginTop': '20px',
        'objectFit': 'contain',
        'justifyContent': 'center',
        'alignItems': 'center',
        'zIndex': 0,
        'height': 0,
        'display': 'none'
    } : {
        'position': 'relative',
        'marginRight': 'auto',
        'marginLeft': 'auto',
        'marginTop': '0px',
        'objectFit': 'contain',
        'alignItems': 'center',
        'justifyContent': 'center',
        'height': 0,
        'display': 'none'
    };
    const [canvasStyling, setCanvasStyling] = useState(originalCanvasStyling);

    const originalSkeletonStyling = window.innerWidth > 800 ? {
        'top': '60',
        'left': '0',
        'marginLeft': 'auto',
        'marginRight': 'auto',
        'marginTop': '20px',
        'objectFit': 'contain',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'zIndex': 0
    } : {
        'position': 'relative',
        'marginRight': 'auto',
        'marginLeft': 'auto',
        'marginTop': '20px',
        'objectFit': 'contain',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center'
    }
    const [skeletonStyling, setSkeletonStyling] = useState(originalSkeletonStyling);

    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // this is a function to load a single image
    const loadImage = async (src, useUniqueSrc) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            const uniqueSrc = `${src}?t=${new Date().getTime()}`;
            if (useUniqueSrc) {
                img.src = uniqueSrc;
            } else {
                img.src = src;
            }
            img.onload = () => resolve(img);
            img.onerror = (error) => {
                reject(error);
            };
        });
    };

    // this is a function to load many images
    const loadLotsOfDeImages = async (imageSet, setter) => {
        const keys = Object.keys(imageSet);
        const values = Object.values(imageSet);
        const imageLoader = await Promise.all(values.map((img) => loadImage(img)));
        const loadedImages = keys.reduce((acc, key, idx) => {
            acc[key] = imageLoader[idx];
            return acc;
        }, {});
        setter(loadedImages);
    };

    // this loads the case images and the animation images
    useEffect(() => {
        const ohThosePicturesYesBootThemUpPlease = async () => {
            try {    
                await Promise.all([
                  loadLotsOfDeImages(caseImagesForLoading, setCaseImages),
                  loadLotsOfDeImages(sequenceImagesForLoading, setPngSequence)
                ]);

            } catch (error) {
                console.error('error loading the images...', error);
            }
          };
        
        if (!caseImages || !sequenceImagesForLoading) {
            ohThosePicturesYesBootThemUpPlease();
        }
    }, []);

    // this loads the image from imageUrl into print, or if there is no imageUrl, it loads the default image
    useEffect(() => {
        const fetchPlaceholder = async () => {
            setDefaultPrint(await loadImage(placeholderpng));
        };
        const fetchPrint = async () => {
          setPrint(await loadImage(imageUrl, true));
          setImWorkingHere(false);
        }
        if(imageUrl) {
          fetchPrint();
        }
        fetchPlaceholder();
    }, [imageUrl]);

    // this updates the canvasWidth and showLeftContainer state variables upon resize, and resizes the skeleton
    useEffect(() => {
        const handleResize = () => {
            setCanvasWidth(window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400));
            setShowLeftContainer(window.innerWidth > 800 ? true : false);
            setSkeletonStyling(window.innerWidth > 800 ? {
                'top': '60',
                'left': '0',
                'marginLeft': 'auto',
                'marginRight': 'auto',
                'marginTop': '20px',
                'objectFit': 'contain',
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'zIndex': 0
            } : {
                'position': 'relative',
                'marginRight': 'auto',
                'marginLeft': 'auto',
                'marginTop': '0px',
                'objectFit': 'contain',
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center'
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    // updates the imWorkingHereRef when imWorkingHere changes
    useEffect(() => {
      imWorkingHereRef.current = imWorkingHere;
    }, [imWorkingHere]);
    
    // updates the selectedSizeRef when selectedSize changes
    useEffect(() => {
      selectedSizeRef.current = selectedSize;
    }, [selectedSize]);

    // this redraws the canvas every time something changes
    useEffect(async () => {
        if (!caseImages || (!imageUrl && !defaultPrint)) {
          return;
        }

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const drawContent = () => {
            if (imWorkingHere) {
              return;
            }
            
            canvas.width = 1748;
            canvas.height = 2480;

            const styleWidth = window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400);

            canvas.style.width = `${(styleWidth-1)*(1748/2480)}px`;
            canvas.style.height = `${styleWidth-1}px`;

            const caseImage = (
              selectedSize === "iPhone 15 Pro Max" ? caseImages.iPhone15ProMax :
              selectedSize === "iPhone 15 Pro" ? caseImages.iPhone15Pro :
              selectedSize === "iPhone 15 Plus" ? caseImages.iPhone15Plus :
              selectedSize === "iPhone 15" ? caseImages.iPhone15 :
              selectedSize === "iPhone 14 Pro Max" ? caseImages.iPhone14ProMax :
              selectedSize === "iPhone 14 Pro" ? caseImages.iPhone14Pro :
              selectedSize === "iPhone 14 Plus" ? caseImages.iPhone14Plus :
              selectedSize === "iPhone 14" ? caseImages.iPhone14 :
              selectedSize === "iPhone 13 Pro Max" ? caseImages.iPhone13ProMax :
              selectedSize === "iPhone 13 Pro" ? caseImages.iPhone13Pro :
              selectedSize === "iPhone 13" ? caseImages.iPhone13 :
              selectedSize === "iPhone 13 mini" ? caseImages.iPhone13mini :
              selectedSize === "iPhone 12 Pro Max" ? caseImages.iPhone12ProMax :
              selectedSize === "iPhone 12 Pro" ? caseImages.iPhone12Pro :
              selectedSize === "iPhone 12" ? caseImages.iPhone12 :
              selectedSize === "iPhone 12 mini" ? caseImages.iPhone12mini :
              selectedSize === "iPhone SE (2020)" ? caseImages.iPhone8 :
              selectedSize === "iPhone 11 Pro Max" ? caseImages.iPhone11ProMax :
              selectedSize === "iPhone 11 Pro" ? caseImages.iPhone11Pro :
              selectedSize === "iPhone 11" ? caseImages.iPhone11 :
              selectedSize === "iPhone XR" ? caseImages.iPhoneXR :
              selectedSize === "iPhone XS Max" ? caseImages.iPhoneXSMax :
              selectedSize === "iPhone XS" ? caseImages.iPhoneXS :
              selectedSize === "iPhone X" ? caseImages.iPhoneX :
              selectedSize === "iPhone 7 Plus" ? caseImages.iPhone7Plus :
              selectedSize === "iPhone 8 Plus" ? caseImages.iPhone8Plus :
              selectedSize === "iPhone 7" ? caseImages.iPhone7 :
              selectedSize === "iPhone 8" ? caseImages.iPhone8 :
              selectedSize === "Galaxy S10" ? caseImages.samsungS10 :
              selectedSize === "Galaxy S10e" ? caseImages.samsungS10e :
              selectedSize === "Galaxy S10+" ? caseImages.samsungS10Plus :
              selectedSize === "Galaxy S20" ? caseImages.samsungS20 :
              selectedSize === "Galaxy S20 Plus" ? caseImages.samsungS20Plus :
              selectedSize === "Galaxy S20 Ultra" ? caseImages.samsungS20Ultra :
              selectedSize === "Galaxy S21" ? caseImages.samsungS21 :
              selectedSize === "Galaxy S21 Plus" ? caseImages.samsungS21Plus :
              selectedSize === "Galaxy S21 Ultra" ? caseImages.samsungS21Ultra :
              selectedSize === "Galaxy S22" ? caseImages.samsungS22 :
              selectedSize === "Galaxy S22 Plus" ? caseImages.samsungS22Plus :
              selectedSize === "Galaxy S22 Ultra" ? caseImages.samsungS22Ultra :
              selectedSize === "Galaxy S23" ? caseImages.samsungS23 :
              selectedSize === "Galaxy S23 Plus" ? caseImages.samsungS23Plus :
              selectedSize === "Galaxy S23 Ultra" ? caseImages.samsungS23Ultra :
              caseImages.iPhone15
            );

            // first number is y-start, second number is y-height (y-end minus y-start)
            const canvasParams = (
              selectedSize === "iPhone 15 Pro Max" ? [133, 2214] :
              selectedSize === "iPhone 15 Pro" ? [231, 2018] :
              selectedSize === "iPhone 15 Plus" ? [134, 2214] :
              selectedSize === "iPhone 15" ? [231, 2019] :
              selectedSize === "iPhone 14 Pro Max" ? [135, 2211] :
              selectedSize === "iPhone 14 Pro" ? [226, 2028] :
              selectedSize === "iPhone 14 Plus" ? [139, 2203] :
              selectedSize === "iPhone 14" ? [233, 2014] :
              selectedSize === "iPhone 13 Pro Max" ? [131, 2219] :
              selectedSize === "iPhone 13 Pro" ? [237, 2007] :
              selectedSize === "iPhone 13" ? [229, 2022] :
              selectedSize === "iPhone 13 mini" ? [307, 1866] :
              selectedSize === "iPhone 12 Pro Max" ? [240, 2000] :
              selectedSize === "iPhone 12 Pro" ? [322, 1836] :
              selectedSize === "iPhone 12" ? [319, 1841] :
              selectedSize === "iPhone 12 mini" ? [412, 1655] :
              selectedSize === "iPhone SE (2020)" ? [378, 1721] :
              selectedSize === "iPhone 11 Pro Max" ? [265, 1951] :
              selectedSize === "iPhone 11 Pro" ? [351, 1780] :
              selectedSize === "iPhone 11" ? [310, 1863] :
              selectedSize === "iPhone XR" ? [307, 1865] :
              selectedSize === "iPhone XS Max" ? [268, 1943] :
              selectedSize === "iPhone X" ? [357, 1765] :
              selectedSize === "iPhone XS" ? [357, 1765] :
              selectedSize === "iPhone 8 Plus" ? [265, 1950] :
              selectedSize === "iPhone 7 Plus" ? [265, 1950] :
              selectedSize === "iPhone 7" ? [378, 1721] :
              selectedSize === "iPhone 8" ? [378, 1721] :
              selectedSize === "Galaxy S10" ? [308, 1876] :
              selectedSize === "Galaxy S10e" ? [360, 1768] :
              selectedSize === "Galaxy S10+" ? [259, 1967] :
              selectedSize === "Galaxy S20" ? [302, 1882] :
              selectedSize === "Galaxy S20 Plus" ? [236, 2015] :
              selectedSize === "Galaxy S20 Ultra" ? [206, 2154] :
              selectedSize === "Galaxy S21" ? [215, 2052] :
              selectedSize === "Galaxy S21 Plus" ? [176, 2128] :
              selectedSize === "Galaxy S21 Ultra" ? [163, 2154] :
              selectedSize === "Galaxy S22" ? [279, 1920] :
              selectedSize === "Galaxy S22 Plus" ? [221, 2044] :
              selectedSize === "Galaxy S22 Ultra" ? [167, 2147] :
              selectedSize === "Galaxy S23" ? [252, 1981] :
              selectedSize === "Galaxy S23 Plus" ? [190, 2105] :
              selectedSize === "Galaxy S23 Ultra" ? [154, 2172] :
              [231, 2019]
            );

            ctx.drawImage(caseImage, 0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = 'destination-over';
            const imageToDraw = imageUrl ? print : defaultPrint;
            ctx.drawImage(imageToDraw, (canvas.width-(canvasParams[1]*(896/1280)))/2, canvasParams[0], canvasParams[1]*(896/1280), canvasParams[1]);

            setCanvasStyling(window.innerWidth > 800 ? {
                'top': '60',
                'left': '0',
                'marginLeft': 'auto',
                'marginRight': 'auto',
                'marginTop': '20px',
                'objectFit': 'contain',
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'zIndex': 0,
            } : {
                'position': 'relative',
                'marginRight': 'auto',
                'marginLeft': 'auto',
                'marginTop': '0px',
                'objectFit': 'contain',
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center',
            });
        };

        let currentFrame = 1;

        const animateThisShi = () => {
            if (!imWorkingHereRef.current) {
              return;
            }

            canvas.width = 705;
            canvas.height = 1000;

            const styleWidth = window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400);

            canvas.style.width = `${(styleWidth-1)*(1748/2480)}px`;
            canvas.style.height = `${styleWidth-1}px`;

            const caseImage = (
              selectedSizeRef.current === "iPhone 15 Pro Max" ? caseImages.iPhone15ProMax :
              selectedSizeRef.current === "iPhone 15 Plus" ? caseImages.iPhone15Plus :
              selectedSizeRef.current === "iPhone 15 Pro" ? caseImages.iPhone15Pro :
              selectedSizeRef.current === "iPhone 15" ? caseImages.iPhone15 :
              selectedSizeRef.current === "iPhone 14 Pro Max" ? caseImages.iPhone14ProMax :
              selectedSizeRef.current === "iPhone 14 Pro" ? caseImages.iPhone14Pro :
              selectedSizeRef.current === "iPhone 14 Plus" ? caseImages.iPhone14Plus :
              selectedSizeRef.current === "iPhone 14" ? caseImages.iPhone14 :
              selectedSizeRef.current === "iPhone 13 Pro Max" ? caseImages.iPhone13ProMax :
              selectedSizeRef.current === "iPhone 13 Pro" ? caseImages.iPhone13Pro :
              selectedSizeRef.current === "iPhone 13" ? caseImages.iPhone13 :
              selectedSizeRef.current === "iPhone 13 mini" ? caseImages.iPhone13mini :
              selectedSizeRef.current === "iPhone 12 Pro Max" ? caseImages.iPhone12ProMax :
              selectedSizeRef.current === "iPhone 12 Pro" ? caseImages.iPhone12Pro :
              selectedSizeRef.current === "iPhone 12" ? caseImages.iPhone12 :
              selectedSizeRef.current === "iPhone 12 mini" ? caseImages.iPhone12mini :
              selectedSizeRef.current === "iPhone SE" ? caseImages.iPhone8 :
              selectedSizeRef.current === "iPhone 11 Pro Max" ? caseImages.iPhone11ProMax :
              selectedSizeRef.current === "iPhone 11 Pro" ? caseImages.iPhone11Pro :
              selectedSizeRef.current === "iPhone 11" ? caseImages.iPhone11 :
              selectedSizeRef.current === "iPhone XR" ? caseImages.iPhoneXR :
              selectedSizeRef.current === "iPhone XS Max" ? caseImages.iPhoneXSMax :
              selectedSizeRef.current === "iPhone XS" ? caseImages.iPhoneXS :
              selectedSizeRef.current === "iPhone X" ? caseImages.iPhoneX :
              selectedSizeRef.current === "iPhone 7 Plus" ? caseImages.iPhone7Plus :
              selectedSizeRef.current === "iPhone 8 Plus" ? caseImages.iPhone8Plus :
              selectedSizeRef.current === "iPhone 7" ? caseImages.iPhone7 :
              selectedSizeRef.current === "iPhone 8" ? caseImages.iPhone8 :
              selectedSizeRef.current === "Galaxy S10" ? caseImages.samsungS10 :
              selectedSizeRef.current === "Galaxy S10e" ? caseImages.samsungS10e :
              selectedSizeRef.current === "Galaxy S10+" ? caseImages.samsungS10Plus :
              selectedSizeRef.current === "Galaxy S20" ? caseImages.samsungS20 :
              selectedSizeRef.current === "Galaxy S20 Plus" ? caseImages.samsungS20Plus :
              selectedSizeRef.current === "Galaxy S20 Ultra" ? caseImages.samsungS20Ultra :
              selectedSizeRef.current === "Galaxy S21" ? caseImages.samsungS21 :
              selectedSizeRef.current === "Galaxy S21 Plus" ? caseImages.samsungS21Plus :
              selectedSizeRef.current === "Galaxy S21 Ultra" ? caseImages.samsungS21Ultra :
              selectedSizeRef.current === "Galaxy S22" ? caseImages.samsungS22 :
              selectedSizeRef.current === "Galaxy S22 Plus" ? caseImages.samsungS22Plus :
              selectedSizeRef.current === "Galaxy S22 Ultra" ? caseImages.samsungS22Ultra :
              selectedSizeRef.current === "Galaxy S23" ? caseImages.samsungS23 :
              selectedSizeRef.current === "Galaxy S23 Plus" ? caseImages.samsungS23Plus :
              selectedSizeRef.current === "Galaxy S23 Ultra" ? caseImages.samsungS23Ultra :
              caseImages.iPhone15
            );

            const frameToDraw = (
              currentFrame === 1 ? pngSequence.v00001 :
              currentFrame === 2 ? pngSequence.v00002 :
              currentFrame === 3 ? pngSequence.v00003 :
              currentFrame === 4 ? pngSequence.v00004 :
              currentFrame === 5 ? pngSequence.v00005 :
              currentFrame === 6 ? pngSequence.v00006 :
              currentFrame === 7 ? pngSequence.v00007 :
              currentFrame === 8 ? pngSequence.v00008 :
              currentFrame === 9 ? pngSequence.v00009 :
              currentFrame === 10 ? pngSequence.v00010 :
              currentFrame === 11 ? pngSequence.v00011 :
              currentFrame === 12 ? pngSequence.v00012 :
              currentFrame === 13 ? pngSequence.v00013 :
              currentFrame === 14 ? pngSequence.v00014 :
              currentFrame === 15 ? pngSequence.v00015 :
              currentFrame === 16 ? pngSequence.v00016 :
              currentFrame === 17 ? pngSequence.v00017 :
              currentFrame === 18 ? pngSequence.v00018 :
              currentFrame === 19 ? pngSequence.v00019 :
              currentFrame === 20 ? pngSequence.v00020 :
              currentFrame === 21 ? pngSequence.v00021 :
              currentFrame === 22 ? pngSequence.v00022 :
              currentFrame === 23 ? pngSequence.v00023 :
              currentFrame === 24 ? pngSequence.v00024 :
              currentFrame === 25 ? pngSequence.v00025 :
              currentFrame === 26 ? pngSequence.v00026 :
              currentFrame === 27 ? pngSequence.v00027 :
              currentFrame === 28 ? pngSequence.v00028 :
              currentFrame === 29 ? pngSequence.v00029 :
              currentFrame === 30 ? pngSequence.v00030 :
              currentFrame === 31 ? pngSequence.v00031 :
              currentFrame === 32 ? pngSequence.v00032 :
              currentFrame === 33 ? pngSequence.v00033 :
              currentFrame === 34 ? pngSequence.v00034 :
              currentFrame === 35 ? pngSequence.v00035 :
              currentFrame === 36 ? pngSequence.v00036 :
              currentFrame === 37 ? pngSequence.v00037 :
              currentFrame === 38 ? pngSequence.v00038 :
              currentFrame === 39 ? pngSequence.v00039 :
              currentFrame === 40 ? pngSequence.v00040 :
              currentFrame === 41 ? pngSequence.v00041 :
              currentFrame === 42 ? pngSequence.v00042 :
              currentFrame === 43 ? pngSequence.v00043 :
              currentFrame === 44 ? pngSequence.v00044 :
              currentFrame === 45 ? pngSequence.v00045 :
              currentFrame === 46 ? pngSequence.v00046 :
              pngSequence.v00001
            );

            ctx.drawImage(caseImage, 0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = 'destination-over';
            ctx.drawImage(frameToDraw, 0, 0, canvas.width*(2480/1748), canvas.height);

            setCanvasStyling(window.innerWidth > 800 ? {
                'top': '60',
                'left': '0',
                'marginLeft': 'auto',
                'marginRight': 'auto',
                'marginTop': '20px',
                'objectFit': 'contain',
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'zIndex': 0,
            } : {
                'position': 'relative',
                'marginRight': 'auto',
                'marginLeft': 'auto',
                'marginTop': '0px',
                'objectFit': 'contain',
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center',
            });
            currentFrame = (currentFrame + 1) % 46;
            setTimeout(() => {
              requestAnimationFrame(animateThisShi);
            }, 1000 / 30);
        } // end of function
        

        try {
            if(!imWorkingHere) {
              drawContent();
              setAnimationIsRunning(false);
            } else {
              if (!animationIsRunning) {
                setAnimationIsRunning(true);
                animateThisShi();
              }
            }
            setIsRenderingDone(true);
        } catch (error) {
            console.error('error drawing images', error);
        }

    }, [print, selectedSize, canvasWidth, caseImages, pngSequence, defaultPrint, imWorkingHere]);

    //this will close the generateImage server connection if the component unmounts
    useEffect(() => {
      return () => {
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
        }
      };
    }, []);

      // this changes the phone make
      const handleMakeChange = async (event) => {
        setMake(event.target.value);
        if (event.target.value === 'iPhone') {
          setSizes(iPhoneSizes);
          setSelectedSize('iPhone 15');
        } else if (event.target.value === 'Samsung') {
          setSizes(samsungSizes);
          setSelectedSize('Galaxy S23');
        }
      };

      // this changes the phone model
      const handleSizeChange = async (event) => {
        setSelectedSize(event.target.value);
      }

      // this changes the selected style
      const handleStyleChange = async (event) => {
        setStyle(event.target.value);
      }

      // for going back to landing page
      const handleRestart = () => {
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
        Cookies.set('lastPage', 'landing', { expires: 7, domain: cookieDomain });
        window.scrollTo({
          top: 0
        });
        setAnimate(true);
        const timer = setTimeout(() => {
          handlePageChange('landing');
        }, 500);
        return () => clearTimeout(timer);
      };

      // updates prompt as user types
      const updatePrompt = (event) => {
        setPrompt(event.target.value);
      };

      // generates new image
      const generateImage = async () => {
        setImWorkingHere(true);
        const generateUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.casebot.io/api/generate?prompt=' : 'http://localhost:3001/api/generate?prompt=';
        const eventSource = new EventSource(generateUrl + encodeURIComponent(prompt.substring(0,1947)) + (style !== 'No Style' ? `&style=${style}` : ''));
        eventSourceRef.current = eventSource;
        eventSource.addEventListener('success', (event) => {
          try {
            const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
            Cookies.set('imageUrl', event.data, { expires: 7 , domain: cookieDomain });
            setImageUrl(event.data);
          } catch (error) {
            console.log(error);
          }
          eventSource.close();
        });
    
        eventSource.addEventListener('failure', (event) => {
          setImWorkingHere(false);
          setShowErrorPopup(true);
          eventSource.close();
          setTimeout(() => {
            setShowErrorPopup(false);
          }, 3000);
        });
      }

      const goToOrdering = () => {
        const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
        Cookies.set('lastPage', 'ordering', { expires: 7 , domain: cookieDomain });
        window.scrollTo({
          top: 0
        });
        const imageKey = imageUrl.split('/').pop();
        navigate(`?case=${imageKey}&make=${make}&model=${selectedSize}`);
      };
      
      const addToFavorites = () => {
        if (savedImages.length < 12 && print && !imWorkingHere) {
          const newSavedImages = savedImages.slice();
          if ( newSavedImages.indexOf(imageUrl) === -1) {
              newSavedImages.unshift(imageUrl);
          } else {
            const index = newSavedImages.indexOf(imageUrl);
            newSavedImages.splice(index, 1);
          }
          setSavedImages(newSavedImages);
          const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.casebot.io' : null;
          Cookies.set('savedImages', JSON.stringify(newSavedImages), { domain: cookieDomain });
        } else if (savedImages.length >= 12) {
          setShowMaxFavoritePopup(true);
          setTimeout(() => {
            setShowMaxFavoritePopup(false);
          }, 3000);
        }
      }

      const share = () => {
        const imageKey = imageUrl.split('/').pop();
        const shareUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? `https://casebot.io?case=${imageKey}` : `localhost:3000?case=${imageKey}`;
        if (navigator.share) {
          navigator.share({
            title: 'CaseBot',
            text: 'Custom Case',
            url: shareUrl
          })
          .catch((error) => console.error(error));
        } else {
          navigator.clipboard.writeText(shareUrl);
          setShowCopyPopup(true);
          setTimeout(() => {
            setShowCopyPopup(false);
          }, 3000);
        }
      }


    return (
        <BigBigBox animate={animate}>
          {showCopyPopup && (
            <Popup><PopupText>Link copied!</PopupText></Popup>
          )}
          {showMaxFavoritePopup && (
            <Popup><PopupText>You already have maximum favorites! Remove some first.</PopupText></Popup>
          )}
          {showErrorPopup && (
            <ErrorPopup><PopupText>Generation failed. Our servers are experiencing high demand--please try again.</PopupText></ErrorPopup>
          )}
            <Container>
                <LeftContainer>
                  <div style={canvasStyling}>
                    <canvas ref={canvasRef}/>
                  </div>

                  {!isRenderingDone && (
                      <ContentLoader 
                          speed={2}
                          width={window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-80) : '100%'}
                          height={window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-80) : Math.min(document.documentElement.clientWidth, 400)-1}
                          viewBox="0 0 1000 1000"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                          style={skeletonStyling}
                      >
                          <path
                              d="M 0 0 L 0 1000 L 1000 1000 L 1000 0 L 0 0 z M 361.53711 133 L 638.46289 133 C 686.22859 133 725 171.77141 725 219.53711 L 725 807.99414 C 725 855.75985 686.22859 894.53125 638.46289 894.53125 L 361.53711 894.53125 C 313.77141 894.53125 275 855.75985 275 807.99414 L 275 219.53711 C 275 171.77141 313.77141 133 361.53711 133 z M 344.22852 236.85352 L 344.22852 721.46875 L 655.77148 721.46875 L 655.77148 236.85352 L 344.22852 236.85352 z M 500.00586 756.08398 C 471.27415 756.08398 448.08398 779.27415 448.08398 808.00586 C 448.08398 836.73756 471.27415 859.92773 500.00586 859.92773 C 528.73756 859.92773 551.92773 836.73756 551.92773 808.00586 C 551.92773 779.27415 528.73756 756.08398 500.00586 756.08398 z "
                          />
                      </ContentLoader>
                  )}
                  <BarForTwoCircleButtons render={isRenderingDone}>
                    <TwoCircleButtons>
                      <FavButton valid={savedImages.includes(imageUrl)} onClick={addToFavorites}>
                        {savedImages.includes(imageUrl) ? (
                          <FavoriteIcon/>
                        ) : (
                          <FavoriteBorderIcon/>
                        )}
                      </FavButton>
                      <ShareButton onClick={share}><IosShareIcon/></ShareButton>
                    </TwoCircleButtons>
                  </BarForTwoCircleButtons>
                </LeftContainer>
                <RightContainer>
                  <Wrapper>
                    <LabelSpan>Design</LabelSpan>
                    <TextArea value={prompt} onChange={updatePrompt} placeholder="Describe what should appear on your case"></TextArea>
                    <BottomButtonsContainer>
                      <SelectInputStyle name="style" value={style} onChange={handleStyleChange} disabled={imWorkingHere}>
                        {styles.map((style) => (
                          <option key={style} value={style}>
                            {style}
                          </option>
                        ))}
                      </SelectInputStyle>
                      {imWorkingHere ? (
                        <ImWorkingHereButton><Dot /><Dot /><Dot /></ImWorkingHereButton>
                      ) : (
                        <GenerateButton disabled={prompt.trim() === ''} valid={prompt.trim() !== ''} onClick={generateImage}>Create Design <AutoFixHighIcon /></GenerateButton>
                      )}
                    </BottomButtonsContainer>
                    <Label>Phone Type</Label>
                    <SelectInput1 name="type" value={make} onChange={handleMakeChange}>
                      {makes.map((make) => (
                        <option key={make} value={make}>
                          {make}
                        </option>
                      ))}
                    </SelectInput1>
                    <SelectInput2 name="size" value={selectedSize} onChange={handleSizeChange} ref={sizeRef}>
                      {sizes.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </SelectInput2>
                    <ProceedButton disabled={!print} valid={print} onClick={goToOrdering}>Proceed <IconContainer><ArrowForwardIcon/></IconContainer></ProceedButton>
                    <RestartText>or: </RestartText><RestartButton onClick={handleRestart}>check out trending designs</RestartButton>
                  </Wrapper>
                </RightContainer>
            </Container>
        </BigBigBox>
    );
};

export default Customize;
