import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`

const BigContainer = styled.div`
    margin-top: 2.5rem;
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    @media (max-width: 600px) {
        width: 85%;
    }
`

const TitleText = styled.h1`
    font-family: 'Righteous';
    font-size: 50px;
    margin-bottom: 20px;
    box-sizing: border-box;
    text-align: center;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

const MailLink = styled.a`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 18px;
    margin-bottom: 1rem;
    animation-delay: 0.6s;
    text-decoration: none;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`

const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    width: 50%;
    max-width: 800px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem;
    color: #ffffff;
    background: linear-gradient(80deg, #405de6, #5851db, #833ab4);
    border: none;
    border-radius: 30px;
    display: block;
    margin-top: 3rem;
    &:hover {
      transform: scale(1.04);
      transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
    margin-left: auto;
    margin-right: auto;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.7s;
    opacity: 0;
    margin-bottom: 10rem;
    @media (max-width: 600px) {
        width: 90%;
    }
`;

const LinkToHome = styled(Link)`
    text-decoration: none;
    width: 50%;
    max-width: 800px;
`

const QuestionBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
`

const QuestionText = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

const AnswerBox  = styled.div`
    margin: 10px 10px;
`;

const AnswerText = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #cccccc;
  margin: 10px 10px;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
`;

const FAQ = () => {
    const [animate, setAnimate] = useState(false);
    const [revealedAnswers, setRevealedAnswers] = useState([false, false, false, false, false])
    
    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        document.title = 'CaseBot | FAQ'
      }, []);

    const handleGoHome = () => {
        setAnimate(true);
        const timer = setTimeout(() => {}, 500);
        return () => clearTimeout(timer);
    }

    const revealAnswer = (position) => {
        const newValue = revealedAnswers[position] === true ? false : true;
        const newRevealedAnswers = revealedAnswers.map((item, index) => index === position ? newValue : item);
        setRevealedAnswers(newRevealedAnswers);
    }

    return (
        <>
            <BigContainer animate = {animate}>
                <TitleText>FAQ</TitleText>
                <QuestionBox onClick = {() => revealAnswer(0)}>
                    <QuestionText>Do you do returns and/or refunds?</QuestionText>
                    {revealedAnswers[0] ? (
                        <RemoveIcon />
                    ) : (
                        <AddIcon />
                    )}
                </QuestionBox>
                {revealedAnswers[0] && (
                    <AnswerBox><AnswerText>Because most cases that CaseBot makes are completely unique, CaseBot cannot accept returns. However, in the rare case that you receive product that is misprinted or otherwise of inferior quality, you are eligible for a refund or a reprint. Please contact <MailLink href="mailto:support@casebot.io">support@casebot.io</MailLink> to initiate that process.</AnswerText></AnswerBox>
                )}
                <Divider />
                <QuestionBox onClick = {() => revealAnswer(1)}>
                    <QuestionText>What's your shipping policy?</QuestionText>
                    {revealedAnswers[1] ? (
                        <RemoveIcon />
                    ) : (
                        <AddIcon />
                    )}
                </QuestionBox>
                {revealedAnswers[1] && (
                    <AnswerBox><AnswerText>CaseBot ships through USPS first class. After placing the order, your order should arrive within 5 business days (most often in 3-4 days). After your order is printed and sent for delivery, you will receive a followup email with details to track your order. You can send an email to <MailLink href="mailto:support@casebot.io">support@casebot.io</MailLink> with any questions about your order.</AnswerText></AnswerBox>
                )}
                <Divider />
                <QuestionBox onClick = {() => revealAnswer(2)}>
                    <QuestionText>How does the image generation work? Is it AI?</QuestionText>
                    {revealedAnswers[2] ? (
                        <RemoveIcon />
                    ) : (
                        <AddIcon />
                    )}
                </QuestionBox>
                {revealedAnswers[2] && (
                    <AnswerBox><AnswerText>Yes, CaseBot is built on open source text-to-image AI models. These models were trained on billions of images to understand how to create artwork of various subjects in various styles. There are still many limitations to the technology. Make sure to check the images you generate for mistakes!</AnswerText></AnswerBox>
                )}
                <Divider />
                <QuestionBox onClick = {() => revealAnswer(3)}>
                    <QuestionText>How can I get better image generations?</QuestionText>
                    {revealedAnswers[3] ? (
                        <RemoveIcon />
                    ) : (
                        <AddIcon />
                    )}
                </QuestionBox>
                {revealedAnswers[3] && (
                    <AnswerBox><AnswerText>Getting consistently great results can be a challenge with complex subjects. In general, a more detailed description will produce a better result, so try describing what you want to make more completely. For example, if your design features a person, is it a closeup shot, or from faraway? Should your image have bright colors, or dark colors? Which colors specifically? Adding these kinds of details in the prompt can improve your designs.</AnswerText></AnswerBox>
                )}
                <LinkToHome to="/" onClick={handleGoHome}>
                    <Button>Return Home</Button>
                </LinkToHome>
            </BigContainer>
        </>
    )
}

export default FAQ;
